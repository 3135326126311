import React from "react";
import { NavLink } from "react-router-dom";
import posed from "react-pose";
import classes from "./DesktopNavigation.module.css";
import { useTranslation } from 'react-i18next';
import InnerNavigation from "../InnerNavigation/InnerNavigation";
import LanguageSelect from "../../../../LanguageSelect/LanguageSelect";

const DropdownNavLink = posed.li({
  hoverable: true
});

function DesktopNavigation(props) {
  const { t } = useTranslation();

  return (
    <>
      <ul className={classes.Nav}>
        <li>
          <NavLink to="/">{t('navigation.home')}</NavLink>
        </li>
        <DropdownNavLink
          onMouseLeave={() => props.setHover(false)}
          onMouseEnter={() => props.setHover(true)}
        >
          <NavLink to="/" className={classes.Disabled}>
            {t("navigation.ourProducts")}
          </NavLink>
          <InnerNavigation
            navItems={props.InnerNavLinks}
            isHover={props.isHover}
          />
        </DropdownNavLink>
        <li>
          <NavLink to="/about">{t("navigation.about")}</NavLink>
        </li>
        <li>
          <NavLink to="/contact">{t("navigation.contact")}</NavLink>
        </li>
        <li>
          <NavLink to="/distributors">{t("navigation.distributors")}</NavLink>
        </li>
      </ul>
      <LanguageSelect />
    </>
  );
}

export default DesktopNavigation;
