import React from "react";
import classes from "./Distributors.module.css";
import ContactData from "../../../datas/Contact/ContactData";

import Card from 'react-bootstrap/Card';

function Distributors(props) {

    return (
        <>
            <div className={classes.Contact}>
                <div className={classes.ContactWrapper}>
                    <div className={classes.ContactDetails}>
                        <div className={classes.ContentDetails}>
                            <h3>Где купить</h3>
                            {ContactData.map((content) => {
                                return (
                                    <>
                                        <Card className={classes.Card}>
                                            <Card.Body>
                                                <Card.Title>{content.region}</Card.Title>
                                                <Card.Subtitle>{content.title}</Card.Subtitle>
                                                <Card.Text>
                                                    <ul>
                                                    {
                                                        content.phones.map((phoneData) => {
                                                            console.log(phoneData);
                                                            return (
                                                             <li className="text-muted">{phoneData}</li>
                                                            )
                                                        })
                                                    }
                                                    </ul>
                                                </Card.Text>
                                            </Card.Body>
                                        </Card>
                                    </>
                                );
                            })}
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Distributors;
