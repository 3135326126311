import React from "react";
import { Link } from "react-router-dom";
import classes from "./Logo.module.css";
import logo from "../../../../assets/logo/logo.png";

function Logo() {
  return (
    <div className={classes.Logo} >
        <Link to={"/"}>
            <img src={logo} alt=""/>
        </Link>
    </div>
  );
}

export default Logo;
