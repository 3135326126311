import React, { useState } from "react";
import Logo from "../Toolbar/Logo/Logo";
import MenuIcon from "./Navigation/MenuIcon/MenuIcon";
import classes from "./Toolbar.module.css";
import Navigation from "./Navigation/Navigation";

 function Toolbar() {
  const [showNav, setshowNav] = useState(false);
  const [active, deactive] = useState(false);

 const toggleNavHandler = () => {
   return setshowNav( !showNav)
  };

  return (
    <div className={classes.Toolbar} >
      <Logo />
      <MenuIcon active={active} deactive={deactive} toggleNav={toggleNavHandler} showNav={showNav} />
      <Navigation  active={active} deactive={deactive} />
    </div>
  );
}

export default Toolbar;
