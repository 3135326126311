import React, { useState } from "react";
import { withRouter } from "react-router-dom";
import classes from "./ProductDetail.module.css";
import Modal from "../../../../UI/Modal/Modal";
import posed from "react-pose";
import { useTranslation } from 'react-i18next';

const CloseIcon = posed.span({

  closed: {
    opacity: 0.7,
    transition: "all 0.2s linear"
  },
  opened:{
    opacity: 1,
    transition: "all 0.2s linear"
  }
});

function ProductDetail(props) {
  const [hover, SetHover] = useState(false);
  const { t } = useTranslation();

  let details = (
    <div key={props.id} className={classes.ProductDetail}>
      <div className={classes.ImageWrapper}>
        <div className={classes.Image}>
          <img src={props.src} alt="" />
        </div>
      </div>
      <div className={classes.Context}>
        <h6 className={classes.Label} style={{ color: props.color }}>
          {t("product.title")}:
        </h6>
        <h3> {props.title}</h3>
        <h6 className={classes.Label} style={{ color: props.color }}>
          {t("product.description")}:{" "}
        </h6>
        <p>{props.context}</p>
        <h6 className={classes.Label} style={{ color: props.color }}>
          {t("product.package")}:
        </h6>
        <div className={classes.TypeWrapper}>
          {Object.values(props.types).map((type, index) => {
            return (
              <div
                key={index + type}
                className={classes.Type}
                style={{
                  backgroundColor: props.color
                }}
              >
                <span>{type}</span>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );

  return (
    <Modal show={props.showModal} close={props.closeModal}>
      <div className={classes.Wrapper}>
        <CloseIcon
          className={classes.CloseIcon}
          onMouseEnter={() => SetHover(true)}
          onMouseLeave={() => SetHover(false)}
          onClick={props.closeModal}
          initialPose={"opened"}
          pose={hover ? "opened" : "closed"}
        >
          <i className="fas fa-times"></i>
        </CloseIcon>
        {details}
      </div>
    </Modal>
  );
}

export default withRouter(ProductDetail);
