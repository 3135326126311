const navigationData = [
    {
        url: "/our-products/pasta",
        titleEn: "Pasta",
        titleRu: "Паста",
        descriptionEn: "",
        descriptionRu: 'Томатная паста ТМ «КУБАНЬ ПРОДУКТ» производится на современных ,автоматизированных линиях из отборных томатов выращенных на собственных полях в Краснодарском крае.'
    },
    {
        url: "/our-products/narsharab",
        titleEn: "Narsharab",
        titleRu: "Наршараб",
        descriptionEn: "Наршараб ТМ КУБАНЬ ПРОДУКТ — это приправа приготовленная получаемая методом сгущения гранатового сока .\n" +
            "Его обычно используют в качестве приправы к мясным и рыбным блюдам, а также к овощам и различным гарнирам",
        descriptionRu: "Наршараб ТМ КУБАНЬ ПРОДУКТ — это приправа приготовленная получаемая методом сгущения гранатового сока .\n" +
            "Его обычно используют в качестве приправы к мясным и рыбным блюдам, а также к овощам и различным гарнирам"
    },
    {
        url: "/our-products/tomatos",
        titleEn: "Tomatos",
        titleRu: "Томаты в томатном соке",
        descriptionEn: "Наршараб ТМ КУБАНЬ ПРОДУКТ — это приправа приготовленная получаемая методом сгущения гранатового сока .\n" +
            "Его обычно используют в качестве приправы к мясным и рыбным блюдам, а также к овощам и различным гарнирам",
        descriptionRu: "Наршараб ТМ КУБАНЬ ПРОДУКТ — это приправа приготовленная получаемая методом сгущения гранатового сока .\n" +
            "Его обычно используют в качестве приправы к мясным и рыбным блюдам, а также к овощам и различным гарнирам"
    },
    {
        url: "/our-products/pickle",
        titleEn: "Marinades and Pickles",
        titleRu: "Маринады и Соленья",
        descriptionEn: "",
        descriptionRu: 'Для производства огурцов маринованных ТМ «Кубань Продукт»  используются огурцы отборные, выращенные на собственных  полях  с применением новейших технологий. Огромное внимание мы уделяем контролю качества выпускаемой продукции и осуществляем жесткий контроль всех этапов процесса производства.'
    },
    {
        url: "/our-products/composto",
        titleEn: "Composto",
        titleRu: "Компоты",
        descriptionEn: "",
        descriptionRu: 'Производство  компотов и варенья  марки ТМ «Кубань Продукт» происходит на автоматизированной линии под постоянным контролем  лаборатории — от входного сырья до упаковки.В нашем производстве компотов и варенья ТМ «Кубань Продукт» мы используем современные технологии, что позволяет нам выпускать продукцию со вкусом  домашнего продукта.\n' +
            'Мы производим следующие виды фруктовой консервации под ТМ «Кубань Продукт»: варенье айвовое, варенье из инжира, варенье ежевичное, варенье из белой черешни, варенье из кизила , варенье малиновое, варенье клубничное, варенье абрикосовое, варенье вишнёвое, а так же компот из фейхоа , компот из фейхоа и боярышника, компот из кизила и компот вишневый\n' +
            '\n' +
            ' Для  производства  используются фрукты, которые  выращены на территории Кубани местными фермерами и сельхоз предприятиями.\n'
    },
    {
        url: "/our-products/jam",
        titleEn: "Jam",
        titleRu: "Варенье",
        descriptionEn: "",
        descriptionRu: 'Производство  компотов и варенья  марки ТМ «Кубань Продукт» происходит на автоматизированной линии под постоянным контролем  лаборатории — от входного сырья до упаковки.В нашем производстве компотов и варенья ТМ «Кубань Продукт» мы используем современные технологии, что позволяет нам выпускать продукцию со вкусом  домашнего продукта.\n' +
            'Мы производим следующие виды фруктовой консервации под ТМ «Кубань Продукт»: варенье айвовое, варенье из инжира, варенье ежевичное, варенье из белой черешни, варенье из кизила , варенье малиновое, варенье клубничное, варенье абрикосовое, варенье вишнёвое, а так же компот из фейхоа , компот из фейхоа и боярышника, компот из кизила и компот вишневый\n' +
            '\n' +
            ' Для  производства  используются фрукты, которые  выращены на территории Кубани местными фермерами и сельхоз предприятиями.\n'
    },
    {
        url: "/our-products/appetizer",
        titleEn: "Appetizer",
        titleRu: "Закуски",
        descriptionEn: "",
        descriptionRu: 'Икра из кабачковая ТМ Кубань Продукт  приготовлена из свежих , спелых  и отборных  кабачков с добавлением репчатого лука, томатов и специй . Все овощи тушатся , перемешиваются и измельчаются до консистенции  пюре.  В процессе производства применяются уникальные технологии благодаря которым , вкус и аромат  детства.'
    },
];

export default navigationData;
