import React from "react";
import MobileDetect from "../Helpers/MobileDetect"
import DesktopLanguageSelect from "./DesktopLanguageSelect/DesktopLanguageSelect";
import MobileLanguageSelect from "./MobileLanguageSelect/MobileLanguageSelect";

function LanguageSelect() {
    const mobileDetect = MobileDetect();

    return (
        !mobileDetect ?
            <>
                <DesktopLanguageSelect />
            </>
        :
            <>
                <MobileLanguageSelect />
            </>
    );
};

export default LanguageSelect;
