import React from "react";
import classes from "../About/About.module.css";
import AchievementsData from "../../../datas/About/AchievementsData";
import ReactFullpage from "@fullpage/react-fullpage";
import YouTube from 'react-youtube';

function About(props) {
    const achievementsData = AchievementsData;
    const lang = localStorage.getItem("i18nextLng") || "ru";
    const screenHeight = window.screen.availHeight;

    return (
        <ReactFullpage
            licenseKey={"AEC02487-DE714926-B6506AB6-3F5153E3"}
            scrollingSpeed={1000} /* Options here */
            scrollBar={true}
            navigation={true}
            render={() => {
                let data = achievementsData.map(item => {
                    let centerImg = '';
                    try {
                        centerImg = require(`../../../datas/About/${item.image}`);
                    }
                    catch (e) {

                    }

                    return (
                        <div key={item.id} className="section">
                            <div className={classes.Contact}>
                                <div className={classes.ContactWrapper}>
                                    <div className={classes.ContactDetails}>
                                        <div className={classes.ContentDetails} dangerouslySetInnerHTML={{__html: lang === 'en' ? item.textEn : item.textRu }}></div>
                                    </div>
                                    <div className={classes.Map}>
                                        { (item.video)
                                            ?
                                                <YouTube videoId={item.video} />
                                            :
                                                <img className={classes.Image} alt={''} src={centerImg} />
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    );
                });
                return (
                    <>
                        <ReactFullpage.Wrapper>
                            <div className={classes.BackImageSide}>
                            </div>
                                {data}
                        </ReactFullpage.Wrapper>
                    </>
                );
            }}
        />
    );
};

export default About;
