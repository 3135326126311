import React, { useRef } from "react";
import classes from "./HomeSection.module.css";
import scrollDown from "../../../../assets/images/scroll-down.png";
import datas from "../../../../datas/Home/DataHome";
import ReactFullpage from "@fullpage/react-fullpage";
import { Link, withRouter } from "react-router-dom";
import { useTranslation } from 'react-i18next';

function Homesection () {

    const refVideo = useRef(null);
    const handlePlayVideo = () => {
        refVideo.current.play();
    };

    window.addEventListener('scroll', () => {
        try {
            if(window.pageYOffset === 0) {
                handlePlayVideo();
            }
        } catch (e) {}

    });

  const { t } = useTranslation();
  return (
    <ReactFullpage
      //fullpage options

      licenseKey={"AEC02487-DE714926-B6506AB6-3F5153E3"}
      scrollingSpeed={1000} /* Options here */
      scrollBar={true}
      render={() => {
        let mainVideo =  require(`../../../../datas/Home/Images/main-video.mp4`);
        let data = datas.map(item => {
            let backImg = '';
          try {
              backImg = require(`../../../../datas/Home/${item.backImg}`);
          } catch (e) {
          }
          const centerImg = require(`../../../../datas/Home/${item.img}`);
          const lang = localStorage.getItem("i18nextLng");

          return (
            // <>
              <div key={item.id} className="section">
                <div className={classes.HomeSection}>
                  <div
                    className={classes.BackImageSide}
                    style={{
                      backgroundImage: "url('" + backImg + "')"
                    }}
                  ></div>

                  <div
                    className={classes.BackColorSide}
                    style={{ background: item.backColor }}
                  ></div>
                  <div
                    className={classes.Image}
                    style={{
                      backgroundImage: "url('" + centerImg + "')"
                    }}
                  >
                    <div className={classes.Content}>
                      <h3>{lang === 'en' ? item.titleEn : item.titleRu}</h3>
                      <p>
                          {lang === 'en' ? item.contentEn : item.contentRu}
                          <br/>
                          <Link to={item.pageLink}>{t("main.seeMore")}</Link>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            // </>
          );
        });
        return (
          <ReactFullpage.Wrapper>
            <div className="section">
              <div
                className={classes.Home}
              >
              <video id="background-video"
                     loop
                     autoPlay
                     muted
                     ref={refVideo}
              >
                  <source src={ mainVideo } type="video/mp4" />
                  <source src={ mainVideo } type="video/ogg" />
                  Your browser does not support the video tag.
              </video>
              <div className={classes.Scroll}>
                  <img src={scrollDown} alt="" />
              </div>
              </div>
            </div>
            {data}
          </ReactFullpage.Wrapper>
        );
      }}
    />
  );
};

export default withRouter(Homesection);
