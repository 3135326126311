const productData = [
  {
    id: "0",
    img: "Images/pasta_70_gr.png",
    color: "#bc1a0f",
    backColor:
        "linear-gradient(135deg, rgb(191, 26, 15) 44%, rgb(36, 0, 0) 100%)",
    types: {
      type1: "70",
      type2: "140",
      type3: "380",
    },
    contextEn:
        "Томатная паста марки  «Кубань Продукт»  производится  из  отборных  и экологически чистых томатов по новейшим технологиям  и на современном оборудовании. Благодаря этому наша паста – это натуральный продукт  настоящего кубанского качества!",
    contextRu:
        "Томатная паста марки  «Кубань Продукт»  производится  из  отборных  и экологически чистых томатов по новейшим технологиям  и на современном оборудовании. Благодаря этому наша паста – это натуральный продукт  настоящего кубанского качества!",
    titleEn: "pasta",
    titleRu: "томатная паста",
    category: "pasta",
  },  {
    id: "01",
    img: "Images/pasta_770_gr.png",
    color: "#bc1a0f",
    backColor:
        "linear-gradient(135deg, rgb(191, 26, 15) 44%, rgb(36, 0, 0) 100%)",
    types: {
      type1: "770",
      type2: "3000",
    },
    contextEn:
        "Томатная паста марки  «Кубань Продукт»  производится  из  отборных  и экологически чистых томатов по новейшим технологиям  и на современном оборудовании. Благодаря этому наша паста – это натуральный продукт  настоящего кубанского качества!",
    contextRu:
        "Томатная паста марки  «Кубань Продукт»  производится  из  отборных  и экологически чистых томатов по новейшим технологиям  и на современном оборудовании. Благодаря этому наша паста – это натуральный продукт  настоящего кубанского качества!",
    titleEn: "pasta",
    titleRu: "томатная паста",
    category: "pasta",
  },
  {
    id: "1",
    img: "Images/pasta_amphora.png",
    color: "#bc1a0f",
    backColor:
        "linear-gradient(135deg, rgb(191, 26, 15) 44%, rgb(36, 0, 0) 100%)",
    types: {
      type1: "530",
    },
    contextEn:
        "Томатная паста марки  «Кубань Продукт»  производится  из  отборных  и экологически чистых томатов по новейшим технологиям  и на современном оборудовании. Благодаря этому наша паста – это натуральный продукт  настоящего кубанского качества!",
    contextRu:
        "Томатная паста марки  «Кубань Продукт»  производится  из  отборных  и экологически чистых томатов по новейшим технологиям  и на современном оборудовании. Благодаря этому наша паста – это натуральный продукт  настоящего кубанского качества!",
    titleEn: "pasta amphora",
    titleRu: "томатная паста",
    category: "pasta",
  },
  {
    id: "02",
    img: "Images/pasta_280_gr.png",
    color: "#bc1a0f",
    backColor:
        "linear-gradient(135deg, rgb(191, 26, 15) 44%, rgb(36, 0, 0) 100%)",
    types: {
      type1: "280",
      type2: "500",
    },
    contextEn:
        "Томатная паста марки  «Кубань Продукт»  производится  из  отборных  и экологически чистых томатов по новейшим технологиям  и на современном оборудовании. Благодаря этому наша паста – это натуральный продукт  настоящего кубанского качества!",
    contextRu:
        "Томатная паста марки  «Кубань Продукт»  производится  из  отборных  и экологически чистых томатов по новейшим технологиям  и на современном оборудовании. Благодаря этому наша паста – это натуральный продукт  настоящего кубанского качества!",
    titleEn: "pasta amphora",
    titleRu: "томатная паста",
    category: "pasta",
  },
  {
    backColor:
        "linear-gradient(135deg, rgb(191, 26, 15) 50%, rgb(36, 0, 0) 100%)",
    id: "2",
    color: "#ba3502",
    img: "Images/pasta.png",
    types: {
      type1: "720",
      type2: "1000",
    },
    titleEn: "tomato",
    titleRu: "томатная паста",
    contextEn:
        "Томатная паста марки  «Кубань Продукт»  производится  из  отборных  и экологически чистых томатов по новейшим технологиям  и на современном оборудовании. Благодаря этому наша паста – это натуральный продукт  настоящего кубанского качества!",
    contextRu:
        "Томатная паста марки  «Кубань Продукт»  производится  из  отборных  и экологически чистых томатов по новейшим технологиям  и на современном оборудовании. Благодаря этому наша паста – это натуральный продукт  настоящего кубанского качества!",
    category: "pasta",
  },
  {
    id: "3",
    contextEn:
        "Томатные соусы ТМ «Кубань Продукт» являются универсальным дополнением и неотъемлемой частью различных блюд.\n" +
        "\n" +
        "Для приготовления соусов предпочитаем выбирать спелые , мясистые и ароматные помидоры, лук , чеснок, острый красный и черный перец и пряная зелень, петрушка, укроп, базилик и лавровый лист.",
    contextRu:
        "Томатные соусы ТМ «Кубань Продукт» являются универсальным дополнением и неотъемлемой частью различных блюд.\n" +
        "\n" +
        "Для приготовления соусов предпочитаем выбирать спелые , мясистые и ароматные помидоры, лук , чеснок, острый красный и черный перец и пряная зелень, петрушка, укроп, базилик и лавровый лист.",
    img: "Images/narsharab.png",
    backColor:
        "linear-gradient(-45deg, rgb(191, 26, 15) 11%, rgb(36, 0, 0) 90%)",

    color: "#9e150c",
    titleEn: "narsharab",
    titleRu: "наршараб",
    category: "narsharab",
    types: {
      type1: "310",
    },
  },
  {
    backColor:
        "linear-gradient(44deg, rgb(20, 117, 40) 60%, rgba(0, 0, 0, 0.84) 100%)",
    id: "6",
    img: "Images/pickle_680_gr.png",
    titleEn: "pickle",
    titleRu: "Огурцы соленые",
    color: "#196428",
    types: {
      type1: "680",
    },
    contextEn:
        "Огурцы соленые ТМ «Кубань Продукт» отбираются под строгим контролем и засаливаются сразу в банке по особой технологии. За особую технологию а международном конкурсе огурцы соленые ТМ «Кубань Продукт» получил щолотую звезду в номинации «Лучший инновационный продукт»",
    contextRu:
        "Огурцы соленые ТМ «Кубань Продукт» отбираются под строгим контролем и засаливаются сразу в банке по особой технологии. За особую технологию а международном конкурсе огурцы соленые ТМ «Кубань Продукт» получил щолотую звезду в номинации «Лучший инновационный продукт»",
    category: "pickle",
  },
  {
    backColor:
        "linear-gradient(44deg, rgb(20, 117, 40) 60%, rgba(0, 0, 0, 0.84) 100%)",
    id: "7",
    img: "Images/pickle_mar_680_gr.png",
    titleEn: "pickle",
    titleRu: "Огурцы маринованные",
    color: "#196428",
    types: {
      type1: "680",
      type2: "1800",
      type3: "3000",
    },
    contextEn:
        "Для производства огурцов соленых и маринованных ТМ «Кубань Продукт» используются огурцы отборные, которые выращены на собственных полях с применением новейших технологий. Огромное внимание ТМ «Кубань Продукт» уделяет контролю качества выпускаемой продукции и осуществляет жесткий контроль всех этапов процесса производства.",
    contextRu:
        "Для производства огурцов соленых и маринованных ТМ «Кубань Продукт» используются огурцы отборные, которые выращены на собственных полях с применением новейших технологий. Огромное внимание ТМ «Кубань Продукт» уделяет контролю качества выпускаемой продукции и осуществляет жесткий контроль всех этапов процесса производства.",
    category: "pickle",
  },
  {
    backColor:
        "linear-gradient(44deg, rgb(20, 117, 40) 60%, rgba(0, 0, 0, 0.84) 100%)",
    id: "8",
    img: "Images/gherkins_350_gr.png",
    titleEn: "gherkins",
    titleRu: "корнишоны маринованные",
    color: "#196428",
    types: {
      type1: "350",
    },
    contextEn:
        "Для производства маринованных корнишонов ТМ «Кубань Продукт» используются только мелкоплодные сорта огурцов с тонкой кожурой ярко-зеленого цвета и плотной мякотью с семечками, выращенные на собственных полях с применением новейших технологий. Размер плодов варьируется от 4 до 8 см.",
    contextRu:
        "Для производства маринованных корнишонов ТМ «Кубань Продукт» используются только мелкоплодные сорта огурцов с тонкой кожурой ярко-зеленого цвета и плотной мякотью с семечками, выращенные на собственных полях с применением новейших технологий. Размер плодов варьируется от 4 до 8 см.",
    category: "pickle",
  },
  {
    backColor:
        "linear-gradient(44deg, rgb(20, 117, 40) 60%, rgba(0, 0, 0, 0.84) 100%)",
    id: "9",
    img: "Images/pickled_tomatoes_680_gr.png",
    titleEn: "pickled tomatoes",
    titleRu: "томаты маринованные",
    color: "#196428",
    types: {
      type1: "680",
      type2: "1800",
    },
    contextEn:
        "Томаты маринованные ТМ «Кубань Продукт» отборные, спелые и невероятно аппетитные - занимают особое место в кулинарных традициях. Сегодня домохозяйки предпочитают использовать томаты маринованные в процессе приготовления закусок и изысканных блюд.",
    contextRu:
        "Томаты маринованные ТМ «Кубань Продукт» отборные, спелые и невероятно аппетитные - занимают особое место в кулинарных традициях. Сегодня домохозяйки предпочитают использовать томаты маринованные в процессе приготовления закусок и изысканных блюд.",
    category: "pickle",
  },
  {
    backColor:
        "linear-gradient(44deg, rgb(20, 117, 40) 60%, rgba(0, 0, 0, 0.84) 100%)",
    id: "10",
    img: "Images/pickled_cherry_tomatoes.png",
    titleEn: "pickled cherry tomatoes",
    titleRu: "томаты черри маринованные",
    color: "#196428",
    types: {
      type1: "680",
    },
    contextEn:
        "Томаты черри ТМ «Кубань Продукт» отборные спелые, аппетитные и выражены с ярким томатным сладким вкусом. Масса плодов от 10 до 30 грамм. Сегодня домохозяйки предпочитают использовать томаты черри для приготовления закусок и изысканных блюд.",
    contextRu:
        "Томаты черри ТМ «Кубань Продукт» отборные спелые, аппетитные и выражены с ярким томатным сладким вкусом. Масса плодов от 10 до 30 грамм. Сегодня домохозяйки предпочитают использовать томаты черри для приготовления закусок и изысканных блюд.",
    category: "pickle",
  },
  {
    backColor:
        "linear-gradient(44deg, rgb(20, 117, 40) 60%, rgba(0, 0, 0, 0.84) 100%)",
    id: "11",
    img: "Images/pickled_assorted.png",
    titleEn: "pickled assorted",
    titleRu: "ассорти маринованное",
    color: "#196428",
    types: {
      type1: "680",
      type2: "1800",
    },
    contextEn:
        "Ассорти из отборных хрустящих огурчиков и спелых, сочных томатов - украсят праздничный стол и дополнения к любому блюду. Продукт изготовлен из высококачественных ингридиентов по современным технологиям.",
    contextRu:
        "Ассорти из отборных хрустящих огурчиков и спелых, сочных томатов - украсят праздничный стол и дополнения к любому блюду. Продукт изготовлен из высококачественных ингридиентов по современным технологиям.",
    category: "pickle",
  },
  {
    backColor:
        "linear-gradient(44deg, rgb(20, 117, 40) 60%, rgba(0, 0, 0, 0.84) 100%)",
    id: "111",
    img: "Images/pickle_mar_squares.png",
    titleEn: "pickled cucumbers",
    titleRu: "огурцы маринованные резанные кубиками",
    color: "#196428",
    types: {
      type1: "400",
    },
    contextEn:
        "Для производства огурцов маринованных резанных кубиками ТМ «Кубань Продукт» используются огурцы отборные 6-9, 9-12 см., которые выращены на собственных полях с применением новейших технологий. Благодаря огурцам маринованным резанных кубиками ТМ «Кубань Продукт» облегчит домохозяйкам приготовление закусок и салатов.",
    contextRu:
        "Для производства огурцов маринованных резанных кубиками ТМ «Кубань Продукт» используются огурцы отборные 6-9, 9-12 см., которые выращены на собственных полях с применением новейших технологий. Благодаря огурцам маринованным резанных кубиками ТМ «Кубань Продукт» облегчит домохозяйкам приготовление закусок и салатов.",
    category: "pickle",
  },
  {
    backColor:
        "linear-gradient(44deg, rgb(20, 117, 40) 60%, rgba(0, 0, 0, 0.84) 100%)",
    id: "111",
    img: "Images/pickle_squares.png",
    titleEn: "pickled cucumbers",
    titleRu: "огурцы соленые резанные кубиками",
    color: "#196428",
    types: {
      type1: "400",
    },
    contextEn:
        "Для производства огурцов соленых резанных кубиками ТМ «Кубань Продукт» используются огурцы отборные 6-9, 9-12 см., которые выращены на собственных полях с применением новейших технологий. Благодаря огурцам соленым резанных кубиками ТМ «Кубань Продукт» облегчит домохозяйкам приготовление закусок и салатов.",
    contextRu:
        "Для производства огурцов соленых резанных кубиками ТМ «Кубань Продукт» используются огурцы отборные 6-9, 9-12 см., которые выращены на собственных полях с применением новейших технологий. Благодаря огурцам соленым резанных кубиками ТМ «Кубань Продукт» облегчит домохозяйкам приготовление закусок и салатов.",
    category: "pickle",
  },
  {
    backColor:
        " linear-gradient(45deg, rgb(250, 162, 0) 20%, rgb(182, 0, 0) 100%)",
    id: "42",
    img: "Images/unpeeled_tomatoes.png",
    types: {
      type1: "680"
    },
    color: "#ba3502",
    titleEn: "unpeeled tomatoes",
    titleRu: "томаты неочищенные в томатном соку",
    contextEn:
        "Томаты неочищенные в томатном соку ТМ КУБАНЬ ПРОДУКТ произведены из томатов выращенных на собственных полях в Краснодарском крае .Томаты неочищенные в томатном соку ТМ КУБАНЬ ПРОДКТ - это очень вкусный продукт , который можно употреблять в течение круглого года , а так же отлично использовать для приготовления соусов, супов, рагу и т.д.",
    contextRu:
        "Томаты неочищенные в томатном соку ТМ КУБАНЬ ПРОДУКТ произведены из томатов выращенных на собственных полях в Краснодарском крае .Томаты неочищенные в томатном соку ТМ КУБАНЬ ПРОДКТ - это очень вкусный продукт , который можно употреблять в течение круглого года , а так же отлично использовать для приготовления соусов, супов, рагу и т.д.",
    category: "pickle",
  },
  {
    backColor:
        " linear-gradient(45deg, rgb(250, 162, 0) 20%, rgb(182, 0, 0) 100%)",
    id: "12",
    img: "Images/pomegranate.png",
    types: {
      type1: "1000",
    },
    color: "#ba3502",
    titleEn: "pomegranate juice",
    titleRu: "сок гранатовый",
    contextEn:
        "Гранатовый сок ТМ КУБАНЬ ПРОДУКТ получают путем отжима плодов граната по последней технологии. Сок имеет освежающий, кислосладкий с некоторой терпкостью вкус. Гранатовый сок ТМ КУБАНЬ ПРОДУКТ прекрасный напиток для устранения жажды и повышения аппетита.",
    contextRu:
        "Гранатовый сок ТМ КУБАНЬ ПРОДУКТ получают путем отжима плодов граната по последней технологии. Сок имеет освежающий, кислосладкий с некоторой терпкостью вкус. Гранатовый сок ТМ КУБАНЬ ПРОДУКТ прекрасный напиток для устранения жажды и повышения аппетита.",
    category: "composto",
  },
  {
    backColor:
        " linear-gradient(45deg, rgb(250, 162, 0) 20%, rgb(182, 0, 0) 100%)",
    id: "13",
    img: "Images/cherry_compote.png",
    types: {
      type1: "1000",
    },
    color: "#ba3502",
    titleEn: "cherry compote",
    titleRu: "компот из черешни",
    contextEn:
        "Черешня – это одна из самых ранних ягод. Именно она открывает фруктовый сезон. В ягодах черешни содержатся каротин, никотиновая кислота, витамин С и другие полезные вещества, в том числе йод. Компот из черешни ТМ КУБАНЬ ПРОДУКТ приготовлены из отборных , спелых и сладких ягод, которые выращены в самых лучших садах юга.",
    contextRu:
        "Черешня – это одна из самых ранних ягод. Именно она открывает фруктовый сезон. В ягодах черешни содержатся каротин, никотиновая кислота, витамин С и другие полезные вещества, в том числе йод. Компот из черешни ТМ КУБАНЬ ПРОДУКТ приготовлены из отборных , спелых и сладких ягод, которые выращены в самых лучших садах юга.",
    category: "composto",
  },
  {
    backColor:
        " linear-gradient(45deg, rgb(250, 162, 0) 20%, rgb(182, 0, 0) 100%)",
    id: "14",
    img: "Images/feijoa_compote.png",
    types: {
      type1: "1000",
    },
    color: "#ba3502",
    titleEn: "feijoa compote",
    titleRu: "компот из фейхоа",
    contextEn:
        "Компоты из фейхоа ТМ КУБАНЬ ПРОДУКТ производятся из отборных и, спелых и сочных ягод фейхоа. При производстве его сохраняются все ценные вещества, содержащиеся в полезном фрукте.",
    contextRu:
        "Компоты из фейхоа ТМ КУБАНЬ ПРОДУКТ производятся из отборных и, спелых и сочных ягод фейхоа. При производстве его сохраняются все ценные вещества, содержащиеся в полезном фрукте.",
    category: "composto",
  },
  {
    backColor:
        " linear-gradient(45deg, rgb(250, 162, 0) 20%, rgb(182, 0, 0) 100%)",
    id: "15",
    img: "Images/feijoa_hawthorn_compote.png",
    types: {
      type1: "1000",
    },
    color: "#ba3502",
    titleEn: "feijoa and hawthorn compote",
    titleRu: "компот из фейхоа и боярышника",
    contextEn:
        "Компоты из фейхоа и боярышника ТМ КУБАНЬ ПРОДУКТ производятся из отборных и, спелых и сочных ягод фейхоа и боярышника . При производстве его сохраняются все ценные вещества, содержащиеся в полезных фруктах. Благодаря ягодам боярышника цвет компота приобретает бордовый насыщенный цвет.",
    contextRu:
        "Компоты из фейхоа и боярышника ТМ КУБАНЬ ПРОДУКТ производятся из отборных и, спелых и сочных ягод фейхоа и боярышника . При производстве его сохраняются все ценные вещества, содержащиеся в полезных фруктах. Благодаря ягодам боярышника цвет компота приобретает бордовый насыщенный цвет.",
    category: "composto",
  },
  {
    backColor:
        " linear-gradient(45deg, rgb(250, 162, 0) 20%, rgb(182, 0, 0) 100%)",
    id: "16",
    img: "Images/thorn_compote.png",
    types: {
      type1: "1000",
    },
    color: "#ba3502",
    titleEn: "thorn compote",
    titleRu: "компот из терна",
    contextEn:
        "Компот из Терна ТМ КУБАНЬ ПРОДУКТ чрезвычайно полезен как для взрослых, так и для детей. Особенно в зимнее время и в межсезонье, когда витаминов не хватает для иммунитета.",
    contextRu:
        "Компот из Терна ТМ КУБАНЬ ПРОДУКТ чрезвычайно полезен как для взрослых, так и для детей. Особенно в зимнее время и в межсезонье, когда витаминов не хватает для иммунитета.",
    category: "composto",
  },
  {
    backColor:
        " linear-gradient(45deg, rgb(250, 162, 0) 20%, rgb(182, 0, 0) 100%)",
    id: "17",
    img: "Images/plum_compote.png",
    types: {
      type1: "1000",
    },
    color: "#ba3502",
    titleEn: "plum compote",
    titleRu: "компот из сливы",
    contextEn:
        "Если вы хотите удивить гостей зимой, то подайте им на стол вместе с отменной едой, сладкий и полезный компот из слив ТМ КУБАНЬ ПРОДУКТ с косточками приготовленный по особому рецепту. Для производства компота используют самые спелые, крепкие и отборные фрукты, которые выращены в самых лучших садах юга.",
    contextRu:
        "Если вы хотите удивить гостей зимой, то подайте им на стол вместе с отменной едой, сладкий и полезный компот из слив ТМ КУБАНЬ ПРОДУКТ с косточками приготовленный по особому рецепту. Для производства компота используют самые спелые, крепкие и отборные фрукты, которые выращены в самых лучших садах юга.",
    category: "composto",
  },
  {
    backColor:
        " linear-gradient(45deg, rgb(250, 162, 0) 20%, rgb(182, 0, 0) 100%)",
    id: "18",
    img: "Images/peach_compote.png",
    types: {
      type1: "1000",
    },
    color: "#ba3502",
    titleEn: "peach compote",
    titleRu: "компот из персиков",
    contextEn:
        "Компот из персиков ТМ КУБАНЬ ПРОДУКТ обладает умеренной сладостью и характерным вкусом и ароматом спелых плодов, созревших под лучами южного солнца. Щадящая термическая обработка сохраняет пользу ярко-оранжевых фруктов и обеспечивает длительный срок хранения.",
    contextRu:
        "Компот из персиков ТМ КУБАНЬ ПРОДУКТ обладает умеренной сладостью и характерным вкусом и ароматом спелых плодов, созревших под лучами южного солнца. Щадящая термическая обработка сохраняет пользу ярко-оранжевых фруктов и обеспечивает длительный срок хранения.",
    category: "composto",
  },
  {
    backColor:
        " linear-gradient(45deg, rgb(250, 162, 0) 20%, rgb(182, 0, 0) 100%)",
    id: "19",
    img: "Images/strawberry_compote.png",
    types: {
      type1: "1000",
    },
    color: "#ba3502",
    titleEn: "strawberry compote",
    titleRu: "компот из клубники",
    contextEn:
        "Компоты Клубничные ТМ КУБАНЬ ПРОДУКТ производится из отборных,спелых и ярко красных ягод клубники. Теперь в снежный зимний денёк вы сможете налить в бокалы яркий, ароматный компот клубничный ТМ Кубань Продукт и ощутить вкус Лета!",
    contextRu:
        "Компоты Клубничные ТМ КУБАНЬ ПРОДУКТ производится из отборных,спелых и ярко красных ягод клубники. Теперь в снежный зимний денёк вы сможете налить в бокалы яркий, ароматный компот клубничный ТМ Кубань Продукт и ощутить вкус Лета!",
    category: "composto",
  },
  {
    backColor:
        " linear-gradient(45deg, rgb(250, 162, 0) 20%, rgb(182, 0, 0) 100%)",
    id: "20",
    img: "Images/dogwood_compote.png",
    types: {
      type1: "1000",
    },
    color: "#ba3502",
    titleEn: "dogwood compote",
    titleRu: "компот из кизила",
    contextEn:
        "Компот из кизила ТМ КУБАНЬ ПРОДУКТ обладает прекрасным алым цветом и характерным вкусом с терпкими нотками и освежающей кислинкой. Для производства компота используют самые спелые, крепкие и ярко красные ягоды кизила, которые выращены в самых лучших садах юга.",
    contextRu:
        "Компот из кизила ТМ КУБАНЬ ПРОДУКТ обладает прекрасным алым цветом и характерным вкусом с терпкими нотками и освежающей кислинкой. Для производства компота используют самые спелые, крепкие и ярко красные ягоды кизила, которые выращены в самых лучших садах юга.",
    category: "composto",
  },
  {
    backColor:
        " linear-gradient(45deg, rgb(250, 162, 0) 20%, rgb(182, 0, 0) 100%)",
    id: "21",
    img: "Images/blackberry_compote.png",
    types: {
      type1: "1000",
    },
    color: "#ba3502",
    titleEn: "blackberry compote",
    titleRu: "компот из ежевики",
    contextEn:
        "Предлагаем вашему вниманию вкусный напиток с насыщенным цветом, ароматом и вкусом — компот из ежевики ТМ КУБАНЬ ПРОДУКТ. Такой компот можно подать как в горячем, так и в холодном виде.",
    contextRu:
        "Предлагаем вашему вниманию вкусный напиток с насыщенным цветом, ароматом и вкусом — компот из ежевики ТМ КУБАНЬ ПРОДУКТ. Такой компот можно подать как в горячем, так и в холодном виде.",
    category: "composto",
  },
  {
    backColor:
        " linear-gradient(45deg, rgb(250, 162, 0) 20%, rgb(182, 0, 0) 100%)",
    id: "22",
    img: "Images/cherry_plum_compote.png",
    types: {
      type1: "1000",
    },
    color: "#ba3502",
    titleEn: "cherry plum compote",
    titleRu: "компот алычовый",
    contextEn:
        "Компот Алычовый ТМ КУБАНЬ ПРОДУКТ чрезвычайно полезен как для взрослых, так и для детей. Особенно в зимнее время и в межсезонье, когда витаминов не хватает для иммунитета. Для производства компота используют самые спелые, крепкие и отборные плоды, которые выращены в самых лучших садах юга России.",
    contextRu:
        "Компот Алычовый ТМ КУБАНЬ ПРОДУКТ чрезвычайно полезен как для взрослых, так и для детей. Особенно в зимнее время и в межсезонье, когда витаминов не хватает для иммунитета. Для производства компота используют самые спелые, крепкие и отборные плоды, которые выращены в самых лучших садах юга России.",
    category: "composto",
  },
  {
    backColor:
        " linear-gradient(45deg, rgb(250, 162, 0) 20%, rgb(182, 0, 0) 100%)",
    id: "23",
    img: "Images/apricot_compote.png",
    types: {
      type1: "1000",
    },
    color: "#ba3502",
    titleEn: "apricot compote",
    titleRu: "компот из абрикосов",
    contextEn:
        "Абрикосовый компот ТМ КУБАНЬ ПРОДУКТ – это вкусный и ароматный напиток, который подарит вам воспоминания о жарком и солнечном лете на Кубани в суровые зимние дни. Для производства компота используют самые спелые, крепкие и ярко желтые абрикосы, которые выращены в самых лучших садах юга.",
    contextRu:
        "Абрикосовый компот ТМ КУБАНЬ ПРОДУКТ – это вкусный и ароматный напиток, который подарит вам воспоминания о жарком и солнечном лете на Кубани в суровые зимние дни. Для производства компота используют самые спелые, крепкие и ярко желтые абрикосы, которые выращены в самых лучших садах юга.",
    category: "composto",
  },
  {
    backColor:
        " linear-gradient(45deg, rgb(250, 162, 0) 20%, rgb(182, 0, 0) 100%)",
    id: "24",
    img: "Images/quince_compote.png",
    types: {
      type1: "1000",
    },
    color: "#ba3502",
    titleEn: "quince compote",
    titleRu: "компот из айвы",
    contextEn:
        "Айвовый компот — очень ароматный напиток, который вы и ваши родные с удовольствием продегустируют в зимние дни в теплом или прохладном виде на десерт. Для производства компота используют самые спелые, крепкие и отборные плоды, которые выращены в самых лучших садах юга России.",
    contextRu:
        "Айвовый компот — очень ароматный напиток, который вы и ваши родные с удовольствием продегустируют в зимние дни в теплом или прохладном виде на десерт. Для производства компота используют самые спелые, крепкие и отборные плоды, которые выращены в самых лучших садах юга России.",
    category: "composto",
  },
  {
    backColor:
        "linear-gradient(-45deg, rgba(221,27,66,1) 29%, rgba(159,0,28,1) 100%)",
    id: "25",
    img: "Images/cherry_jam.png",
    titleEn: "cherry jam",
    titleRu: "варенье из черешни",
    color: "#a3150c",
    types: {
      type2: "400",
    },
    contextEn:
        "Варенье из красной черешни ТМ КУБАНЬ ПРОДУКТ - это настоящее лакомство с восхитительным вкусом и ароматом, изготовлены из самых сочных ягод и фруктов лучших сортов. Варенье ТМ КУБАНЬ ПРОДУКТ с Кубани известны далеко за ее пределами неповторимым вкусом и уникальными полезными витаминами.",
    contextRu:
        "Варенье из красной черешни ТМ КУБАНЬ ПРОДУКТ - это настоящее лакомство с восхитительным вкусом и ароматом, изготовлены из самых сочных ягод и фруктов лучших сортов. Варенье ТМ КУБАНЬ ПРОДУКТ с Кубани известны далеко за ее пределами неповторимым вкусом и уникальными полезными витаминами.",
    category: "jam",
  },
  {
    backColor:
        "linear-gradient(-45deg, rgba(221,27,66,1) 29%, rgba(159,0,28,1) 100%)",
    id: "26",
    img: "Images/rose_jam.png",
    titleEn: "rose jam",
    titleRu: "варенье из розы",
    color: "#a3150c",
    types: {
      type2: "400",
    },
    contextEn:
        "Варенье из лепестков роз ТМ КУБАНЬ ПРОДУКТ – нежное, ароматное, с тонким вкусом. Считается поистине королевским изысканным видом варенья, особым десертом, обладающим, помимо его превосходных вкусовых качеств, целебным эффектом. Варенье ТМ КУБАНЬ ПРОДУКТ с Кубани известны далеко за ее пределами неповторимым вкусом и уникальными полезными витаминами.",
    contextRu:
        "Варенье из лепестков роз ТМ КУБАНЬ ПРОДУКТ – нежное, ароматное, с тонким вкусом. Считается поистине королевским изысканным видом варенья, особым десертом, обладающим, помимо его превосходных вкусовых качеств, целебным эффектом. Варенье ТМ КУБАНЬ ПРОДУКТ с Кубани известны далеко за ее пределами неповторимым вкусом и уникальными полезными витаминами.",
    category: "jam",
  },
  {
    backColor:
        "linear-gradient(-45deg, rgba(221,27,66,1) 29%, rgba(159,0,28,1) 100%)",
    id: "27",
    img: "Images/nut_jam.png",
    titleEn: "walnut jam",
    titleRu: "варенье из грецкого ореха",
    color: "#a3150c",
    types: {
      type2: "400",
    },
    contextEn:
        "Ароматное варенье из грецкого ореха ТМ КУБАНЬ ПРОДУКТ обладает глубоким янтарным цветом с лёгкой кислинкой и восхитительным послевкусием. Для варенья берутся зеленые, неспелые орехи. На Юге этот орех считается священным деревом. Варенье ТМ КУБАНЬ ПРОДУКТ с Кубани известны далеко за ее пределами неповторимым вкусом и уникальными полезными витаминами.",
    contextRu:
        "Ароматное варенье из грецкого ореха ТМ КУБАНЬ ПРОДУКТ обладает глубоким янтарным цветом с лёгкой кислинкой и восхитительным послевкусием. Для варенья берутся зеленые, неспелые орехи. На Юге этот орех считается священным деревом. Варенье ТМ КУБАНЬ ПРОДУКТ с Кубани известны далеко за ее пределами неповторимым вкусом и уникальными полезными витаминами.",
    category: "jam",
  },
  {
    backColor:
        "linear-gradient(-45deg, rgba(221,27,66,1) 29%, rgba(159,0,28,1) 100%)",
    id: "28",
    img: "Images/raspberry_jam.png",
    titleEn: "raspberry jam",
    titleRu: "варенье из малины",
    color: "#a3150c",
    types: {
      type2: "400",
    },
    contextEn:
        "Варенье из малины ТМ КУБАНЬ ПРОДУКТ – незаменимый, самый сладкий, красивый, витаминный, полезный десерт на вашем столе, родом из детства. Идеальное угощение к чаепитию. Прекрасная начинка для выпечки. Малиновое варенье обладает противопростудным и жаропонижающим свойствами. Варенье ТМ КУБАНЬ ПРОДУКТ с Кубани известны далеко за ее пределами неповторимым вкусом и уникальными полезными витаминами.",
    contextRu:
        "Варенье из малины ТМ КУБАНЬ ПРОДУКТ – незаменимый, самый сладкий, красивый, витаминный, полезный десерт на вашем столе, родом из детства. Идеальное угощение к чаепитию. Прекрасная начинка для выпечки. Малиновое варенье обладает противопростудным и жаропонижающим свойствами. Варенье ТМ КУБАНЬ ПРОДУКТ с Кубани известны далеко за ее пределами неповторимым вкусом и уникальными полезными витаминами.",
    category: "jam",
  },
  {
    backColor:
        "linear-gradient(-45deg, rgba(221,27,66,1) 29%, rgba(159,0,28,1) 100%)",
    id: "29",
    img: "Images/strawberry_jam.png",
    titleEn: "strawberry jam",
    titleRu: "варенье из клубники",
    color: "#a3150c",
    types: {
      type2: "400",
    },
    contextEn:
        "Ароматное варенье из клубники ТМ КУБАНЬ ПРОДУКТ произведено по традиционной рецептуре, только клубника и сахар. Не содержит консервантов и красителей. Идеальное угощение к чаепитию. Прекрасная начинка для выпечки. Варенье ТМ КУБАНЬ ПРОДУКТ с Кубани известны далеко за ее пределами неповторимым вкусом и уникальными полезными витаминами.",
    contextRu:
        "Ароматное варенье из клубники ТМ КУБАНЬ ПРОДУКТ произведено по традиционной рецептуре, только клубника и сахар. Не содержит консервантов и красителей. Идеальное угощение к чаепитию. Прекрасная начинка для выпечки. Варенье ТМ КУБАНЬ ПРОДУКТ с Кубани известны далеко за ее пределами неповторимым вкусом и уникальными полезными витаминами.",
    category: "jam",
  },
  {
    backColor:
        "linear-gradient(-45deg, rgba(221,27,66,1) 29%, rgba(159,0,28,1) 100%)",
    id: "30",
    img: "Images/dogwood_jam.png",
    titleEn: "dogwood jam",
    titleRu: "варенье из кизила",
    color: "#a3150c",
    types: {
      type2: "400",
    },
    contextEn:
        "Сладкий вкус душистого варенья из кизила ТМ КУБАНЬ ПРОДУКТ обладает характерными кислыми нотками кизила. Варенья из кизила ТМ КУБАНЬ ПРОДУКТ обладает темно-рубинового цвета с жидкой консистенцией, которая порадует своей оригинальностью и станет удачным дополнением к горячему чаю. Ягоды кизила тщательно отбираются и варятся по особой технологии - вот почему варенью удается сохранить их естественный вкус. Варенье ТМ КУБАНЬ ПРОДУКТ с Кубани известны далеко за ее пределами неповторимым вкусом и уникальными полезными витаминами.",
    contextRu:
        "Сладкий вкус душистого варенья из кизила ТМ КУБАНЬ ПРОДУКТ обладает характерными кислыми нотками кизила. Варенья из кизила ТМ КУБАНЬ ПРОДУКТ обладает темно-рубинового цвета с жидкой консистенцией, которая порадует своей оригинальностью и станет удачным дополнением к горячему чаю. Ягоды кизила тщательно отбираются и варятся по особой технологии - вот почему варенью удается сохранить их естественный вкус. Варенье ТМ КУБАНЬ ПРОДУКТ с Кубани известны далеко за ее пределами неповторимым вкусом и уникальными полезными витаминами.",
    category: "jam",
  },
  {
    backColor:
        "linear-gradient(-45deg, rgba(221,27,66,1) 29%, rgba(159,0,28,1) 100%)",
    id: "31",
    img: "Images/fig_jam.png",
    titleEn: "fig jam",
    titleRu: "варенье из инжира",
    color: "#a3150c",
    types: {
      type2: "400",
    },
    contextEn:
        "Варенье из инжира ТМ КУБАНЬ ПРОДУКТ невероятно полезный и вкусный деликатес. Прежде всего, хочется отметить, что инжир богат калием, поэтому он полезен для сердца и для укрепления костей и зубов особенно растущего организма детей. Рекомендуется употреблять в чистом виде к чашечке чая, а также использовать для приготовления десертов. Варенье ТМ КУБАНЬ ПРОДУКТ с Кубани известны далеко за ее пределами неповторимым вкусом и уникальными полезными витаминами.",
    contextRu:
        "Варенье из инжира ТМ КУБАНЬ ПРОДУКТ невероятно полезный и вкусный деликатес. Прежде всего, хочется отметить, что инжир богат калием, поэтому он полезен для сердца и для укрепления костей и зубов особенно растущего организма детей. Рекомендуется употреблять в чистом виде к чашечке чая, а также использовать для приготовления десертов. Варенье ТМ КУБАНЬ ПРОДУКТ с Кубани известны далеко за ее пределами неповторимым вкусом и уникальными полезными витаминами.",
    category: "jam",
  },
  {
    backColor:
        "linear-gradient(-45deg, rgba(221,27,66,1) 29%, rgba(159,0,28,1) 100%)",
    id: "32",
    img: "Images/blackberry_jam.png",
    titleEn: "blackberry jam",
    titleRu: "варенье из ежевики",
    color: "#a3150c",
    types: {
      type2: "400",
    },
    contextEn:
        "Варенье из ежевики ТМ КУБАНЬ ПРОДУКТ — невероятно вкусное и ароматное, а сколько витаминов в одной баночке с такого десерта, все не перечислишь. Ягоды ежевики очень схожи с ягодами малины своими полезными качествами для организма человека, единственное – они отличаются цветом и вкусовыми характеристиками. Варенье ТМ КУБАНЬ ПРОДУКТ с Кубани известны далеко за ее пределами неповторимым вкусом и уникальными полезными витаминами.",
    contextRu:
        "Варенье из ежевики ТМ КУБАНЬ ПРОДУКТ — невероятно вкусное и ароматное, а сколько витаминов в одной баночке с такого десерта, все не перечислишь. Ягоды ежевики очень схожи с ягодами малины своими полезными качествами для организма человека, единственное – они отличаются цветом и вкусовыми характеристиками. Варенье ТМ КУБАНЬ ПРОДУКТ с Кубани известны далеко за ее пределами неповторимым вкусом и уникальными полезными витаминами.",
    category: "jam",
  },
  {
    backColor:
        "linear-gradient(-45deg, rgba(221,27,66,1) 29%, rgba(159,0,28,1) 100%)",
    id: "33",
    img: "Images/cherries_jam.png",
    titleEn: "cherries jam",
    titleRu: "варенье из вишни",
    color: "#a3150c",
    types: {
      type2: "400",
    },
    contextEn:
        "Варенье из вишни ТМ КУБАНЬ ПРОДУКТ можно есть просто так ложечкой, можно использовать его для приготовления выпечки, его можно класть в мороженое и другие десерты. Варенье из вишни ТМ КУБАНЬ ПРОДУКТ изготовлено по традиционному домашнему рецепту из сочных, спелых фруктов без использования консервантов и красителей. Варенье ТМ КУБАНЬ ПРОДУКТ с Кубани известны далеко за ее пределами неповторимым вкусом и уникальными полезными витаминами.",
    contextRu:
        "Варенье из вишни ТМ КУБАНЬ ПРОДУКТ можно есть просто так ложечкой, можно использовать его для приготовления выпечки, его можно класть в мороженое и другие десерты. Варенье из вишни ТМ КУБАНЬ ПРОДУКТ изготовлено по традиционному домашнему рецепту из сочных, спелых фруктов без использования консервантов и красителей. Варенье ТМ КУБАНЬ ПРОДУКТ с Кубани известны далеко за ее пределами неповторимым вкусом и уникальными полезными витаминами.",
    category: "jam",
  },
  {
    backColor:
        "linear-gradient(-45deg, rgba(221,27,66,1) 29%, rgba(159,0,28,1) 100%)",
    id: "34",
    img: "Images/white_cherry_jam.png",
    titleEn: "white cherry jam",
    titleRu: "варенье из белой черешни",
    color: "#a3150c",
    types: {
      type2: "400",
    },
    contextEn:
        "Варенье из белой черешни ТМ КУБАНЬ ПРОДУКТ – одно из самых красивых. Оно похоже на кусочки янтаря, погруженные в мед. Черешня мягкая, но плотная и мясистая, нежная и не приторно сладкая. Кстати, черешня обладает удивительным свойством – ускоряет обмен веществ! Варенье ТМ КУБАНЬ ПРОДУКТ с Кубани известны далеко за ее пределами неповторимым вкусом и уникальными полезными витаминами.",
    contextRu:
        "Варенье из белой черешни ТМ КУБАНЬ ПРОДУКТ – одно из самых красивых. Оно похоже на кусочки янтаря, погруженные в мед. Черешня мягкая, но плотная и мясистая, нежная и не приторно сладкая. Кстати, черешня обладает удивительным свойством – ускоряет обмен веществ! Варенье ТМ КУБАНЬ ПРОДУКТ с Кубани известны далеко за ее пределами неповторимым вкусом и уникальными полезными витаминами.",
    category: "jam",
  },
  {
    backColor:
        "linear-gradient(-45deg, rgba(221,27,66,1) 29%, rgba(159,0,28,1) 100%)",
    id: "35",
    img: "Images/quince_jam.png",
    titleEn: "quince jam",
    titleRu: "варенье из айвы",
    color: "#a3150c",
    types: {
      type2: "400",
    },
    contextEn:
        "Золотисто-янтарное душистое варенье из айвы ТМ КУБАНЬ ПРОДУКТ - полезный и вкусный деликатес. Плоды айвы тщательно отбираются и готовятся по особой технологии - вот почему варенью удается сохранить их естественный вкус, но при этом дольки фрукта становятся мягкими и нежными. Такое варенье украсит любое чаепитие и станет отличным дополнением к блинам, оладьям, выпечке и утренней каше. Варенье ТМ КУБАНЬ ПРОДУКТ с Кубани известны далеко за ее пределами неповторимым вкусом и уникальными полезными витаминами.",
    contextRu:
        "Золотисто-янтарное душистое варенье из айвы ТМ КУБАНЬ ПРОДУКТ - полезный и вкусный деликатес. Плоды айвы тщательно отбираются и готовятся по особой технологии - вот почему варенью удается сохранить их естественный вкус, но при этом дольки фрукта становятся мягкими и нежными. Такое варенье украсит любое чаепитие и станет отличным дополнением к блинам, оладьям, выпечке и утренней каше. Варенье ТМ КУБАНЬ ПРОДУКТ с Кубани известны далеко за ее пределами неповторимым вкусом и уникальными полезными витаминами.",
    category: "jam",
  },
  {
    backColor:
      " linear-gradient(45deg, rgb(250, 162, 0) 20%, rgb(182, 0, 0) 100%)",
    id: "36",
    img: "Images/squash_530_gr.png",
    types: {
      type1: "530"
    },
    color: "#ba3502",
    titleEn: "zucchini caviar",
    titleRu: "икра из кабачков",
    contextEn:
      "Икра из кабачковая ТМ Кубань Продукт приготовлена из свежих , спелых и отборных кабачков с добавлением репчатого лука, томатов и специй . Все овощи тушатся , перемешиваются и измельчаются до консистенции пюре. В процессе производства применяются уникальные технологии благодаря которым , вкус и аромат детства.",
    contextRu:
      "Икра из кабачковая ТМ Кубань Продукт приготовлена из свежих , спелых и отборных кабачков с добавлением репчатого лука, томатов и специй . Все овощи тушатся , перемешиваются и измельчаются до консистенции пюре. В процессе производства применяются уникальные технологии благодаря которым , вкус и аромат детства.",
    category: "appetizer",
  },
  {
    backColor:
      " linear-gradient(45deg, rgb(250, 162, 0) 20%, rgb(182, 0, 0) 100%)",
    id: "37",
    img: "Images/fried_eggplant.png",
    types: {
      type1: "620"
    },
    color: "#ba3502",
    titleEn: "fried eggplant",
    titleRu: "жаренные баклажаны",
    contextEn:
      "Вам не нужно разжигать мангал для барбекю, чтобы сделать барбекю салат или баклажаны на огне! Вы насладитесь желаемым вкусом с новым БАРБЕКЮ САЛАТ и БАКЛАЖАНАМИ НА ОГНЕ ТМ \"КУБАНЬ ПРОДУКТ\". Нашу продукцию вы можете использовать как душе угодно в виде холодных закусок , в качестве гарнира для мяса и птицы, а так же как дополнительный ингредиент изысканных блюд.",
    contextRu:
      "Вам не нужно разжигать мангал для барбекю, чтобы сделать барбекю салат или баклажаны на огне! Вы насладитесь желаемым вкусом с новым БАРБЕКЮ САЛАТ и БАКЛАЖАНАМИ НА ОГНЕ ТМ \"КУБАНЬ ПРОДУКТ\". Нашу продукцию вы можете использовать как душе угодно в виде холодных закусок , в качестве гарнира для мяса и птицы, а так же как дополнительный ингредиент изысканных блюд.",
    category: "appetizer",
  },
  {
    backColor:
      " linear-gradient(45deg, rgb(250, 162, 0) 20%, rgb(182, 0, 0) 100%)",
    id: "38",
    img: "Images/BBQ_salad.png",
    types: {
      type1: "300",
      type2: "630"
    },
    color: "#ba3502",
    titleEn: "BBQ salad",
    titleRu: "барбекю салат",
    contextEn:
      "Барбекю салат ТМ Кубань Продукт великолепный летний салат из запеченных овощей которые выращены на экологически чистых полях. Отлично подходят к жареному мясу, птице, рыбе и печеному картофелю.",
    contextRu:
      "Барбекю салат ТМ Кубань Продукт великолепный летний салат из запеченных овощей которые выращены на экологически чистых полях. Отлично подходят к жареному мясу, птице, рыбе и печеному картофелю.",
    category: "appetizer",
  },
  {
    backColor:
      " linear-gradient(45deg, rgb(250, 162, 0) 20%, rgb(182, 0, 0) 100%)",
    id: "39",
    img: "Images/lecho.png",
    types: {
      type1: "680"
    },
    color: "#ba3502",
    titleEn: "lecho",
    titleRu: "лечо",
    contextEn:
      "Лечо Кубань Продукт - один из самых любимых видов консервированных закусок. Открыв баночку, вы получите и острую закуску, и отличный гарнир к мясу.\n" +
        "Рецепт Лечо Кубань Продукт достаточно прост: свежий красный перец и томатный сок. И не думайте, что это – слишком просто и скучно: поверьте: в этом минимализме и есть самый главный секрет вкусного лечо Кубань Продукт",
    contextRu:
      "КУБАНЬ ПРОДУКТ Лечо, стеклянная банка",
    category: "appetizer",
  },
  {
    backColor:
      " linear-gradient(45deg, rgb(250, 162, 0) 20%, rgb(182, 0, 0) 100%)",
    id: "40",
    img: "Images/peeled_tomatoes.png",
    types: {
      type1: "680",
    },
    color: "#ba3502",
    titleEn: "peeled tomatoes",
    titleRu: "томаты очищенные",
    contextEn:
      "Томаты очищенные в собственном соку ТМ КУБАНЬ ПРОДУКТ произведены из томатов, выращенных на собственных полях в Краснодарском крае. Уникальная рецептура и современные технологии помогают сохранить большинство питательных веществ и полезных свойств. Томаты очищенные в собственном соку ТМ КУБАНЬ ПРОДУКТ обладают отменным вкусом и являются незаменимым блюдом для тех, кто соблюдает пост или придерживается диеты. Используется не только как готовое блюдо, но и станет дополнительным ингредиентом многих блюд",
    contextRu:
      "Томаты очищенные в собственном соку ТМ КУБАНЬ ПРОДУКТ произведены из томатов, выращенных на собственных полях в Краснодарском крае. Уникальная рецептура и современные технологии помогают сохранить большинство питательных веществ и полезных свойств. Томаты очищенные в собственном соку ТМ КУБАНЬ ПРОДУКТ обладают отменным вкусом и являются незаменимым блюдом для тех, кто соблюдает пост или придерживается диеты. Используется не только как готовое блюдо, но и станет дополнительным ингредиентом многих блюд",
    category: "tomatos",
  },
  {
    backColor:
        " linear-gradient(45deg, rgb(250, 162, 0) 20%, rgb(182, 0, 0) 100%)",
    id: "43",
    img: "Images/sliced_tomatoes.png",
    types: {
      type1: "400"
    },
    color: "#ba3502",
    titleEn: "sliced tomatoes in tomato juice",
    titleRu: "томаты нарезанные в томатном соке",
    contextEn:
        "Томаты нарезанные в томатном соку ТМ КУБАНЬ ПРОДУКТ произведены из томатов, выращенных на собственных полях в Краснодарском крае.\n" +
        "На текущий момент мы единственные кто обладает технологией по выпуску данной продукции. Таким образом, в отличии от других мы можем предложить востребованный товар по конкурентной цене.",
    contextRu:
        "Томаты нарезанные в томатном соку ТМ КУБАНЬ ПРОДУКТ произведены из томатов, выращенных на собственных полях в Краснодарском крае.\n" +
        "На текущий момент мы единственные кто обладает технологией по выпуску данной продукции. Таким образом, в отличии от других мы можем предложить востребованный товар по конкурентной цене.",
    category: "tomatos",
  },
  {
    backColor:
      " linear-gradient(45deg, rgb(250, 162, 0) 20%, rgb(182, 0, 0) 100%)",
    id: "41",
    img: "Images/peeled_tomatoes_juice.png",
    types: {
      type1: "400",
      type2: "800"
    },
    color: "#ba3502",
    titleEn: "peeled tomatoes in tomato juice",
    titleRu: "томаты очищенные в томатном соке",
    contextEn:
      "Томаты очищенные в собственном соку ТМ КУБАНЬ ПРОДУКТ произведены из томатов выращенных на собственных полях в Краснодарском крае. Уникальная рецептура и современные технологии помогают сохранить большинство питательных веществ и полезных свойств.\n" +
        "Томаты очищенные в собственном соку ТМ КУБАНЬ ПРОДУКТ обладают отменным вкусом и являются незаменимым блюдом для тех, кто соблюдает пост или придерживается диеты. Используется не только как готовое блюдо, но и станет дополнительным ингредиентом многих блюд.",
    contextRu:
        "Томаты очищенные в собственном соку ТМ КУБАНЬ ПРОДУКТ произведены из томатов выращенных на собственных полях в Краснодарском крае. Уникальная рецептура и современные технологии помогают сохранить большинство питательных веществ и полезных свойств.\n" +
        "Томаты очищенные в собственном соку ТМ КУБАНЬ ПРОДУКТ обладают отменным вкусом и являются незаменимым блюдом для тех, кто соблюдает пост или придерживается диеты. Используется не только как готовое блюдо, но и станет дополнительным ингредиентом многих блюд.",
    category: "tomatos",
  }
];

export default productData;
