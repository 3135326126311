import React, { useState } from "react";
import classes from "./Product.module.css";
import posed from "react-pose";
import { withRouter} from "react-router-dom";

const ImageLayer = posed.div({
  hoverable: true
});

const TransparentDiv = posed.div({
  closed: {
    left: 0,
    backgroundColor: "rgba(235, 229, 229)"
  },

  open: {
    left: "-10px",
    backgroundColor: "rgba(235, 229, 229, 0.307)"
  }
});
const Img = posed.img({
  closed: {
    scale: 1,
    transition: "all 0.2s linear"
  },
  open: {
    scale: 1.1,
    transition: "all 0.2s linear"
  }
});

const StaticDiv = posed.div({
  closed: {
    left: 0
  },

  open: {
    left: "10px"
  }
});

const Product = props => {
  const [isHover, SetHover] = useState(false);

  return (
    <>
      <ImageLayer
        onMouseEnter={() => SetHover(true)}
        onMouseLeave={() => SetHover(false)}
        onClick={props.clicked}
        className={classes.ImageLayer}
        pose={isHover ? "open" : "closed"}
      >
        <StaticDiv
          pose={isHover ? "open" : "closed"}
          className={classes.StaticDiv}
        ></StaticDiv>
        <Img src={props.src} alt={props.alt} />

        <TransparentDiv
          pose={isHover ? "open" : "closed"}
          className={classes.TransparentDiv}
        ></TransparentDiv>
      </ImageLayer>

      <p className={classes.ProductName}>{props.title}</p>
    </>
  );
};

export default withRouter(Product);
