import React from "react";
import { Link } from "react-router-dom";
import classes from "./InnerNavigation.module.css";
import posed from "react-pose";

const Sidebar = posed.nav({
  open: { y: "0%", staggerChildren: 150, opacity: 1 },
  closed: { y: "-200%", opacity: 0 , }
});

const NavItem = posed.li({
  open: { opacity: 1 },
  closed: { opacity: 0 }
});

function InnerNavigation(props) {
  const lang = localStorage.getItem("i18nextLng");
  return (
      <Sidebar pose={props.isHover ? "open" : "closed"}>
        <ul className={classes.InnerNavigation}>
          {props.navItems.map(({ url, titleEn, titleRu }, index) => {
            return (
              <NavItem key={index}>
                <Link className={classes.InnerLink} to={url}>{lang === 'en' ? titleEn : titleRu}</Link>
              </NavItem>
            );
          })}
        </ul>
      </Sidebar>
  );
}

export default InnerNavigation;
