import React from "react";
import Layout from "./components/Layout/Layout";
import Homesection from "./components/Pages/Home/HomeSection/HomeSection";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import Products from "./components/Pages/Products/Products";
import Contact from "./components/Pages/Contact/Contact";
import About from "./components/Pages/About/About";
import Distributors from "./components/Pages/Distributors/Distributors";
import 'bootstrap/dist/css/bootstrap.min.css';

function App(props) {

  return (
    <div>
      <Router>
        <Switch>
          <Layout>
            <Route path="/" exact component={Homesection} />
            <Route path="/our-products/:productId/" exact component={Products} />
            <Route path="/contact" exact component={Contact} />
            <Route path="/about" exact component={About} />
            <Route path="/distributors" exact component={Distributors} />
          </Layout>
        </Switch>
      </Router>
    </div>
  );
}
export default App;

