import React, { Component } from "react";
import productData from "../../../datas/Products/DataProduct";
import classes from "./Products.module.css";
import Product from "./Product/Product";
import "owl.carousel/dist/assets/owl.carousel.min.css";
import "owl.carousel/dist/assets/owl.theme.default.min.css";
import ProductDetail from "./ProductDetail/ProductDetail";
import { withRouter } from "react-router-dom";
import ReactOwlCarousel from "react-owl-carousel";
import i18n from "i18next";

let prev = classes.owlPrev;
let next = classes.owlNext;

i18n.on("languageChanged", () => {
  window.location.reload();
});

class Products extends Component {
  state = {
    showModal: false,
    selectedProduct: ""
  };

  componentDidMount(){
    productData
    .filter((item) => item.category === this.props.match.params.productId)
    .forEach((content, index) => {
      if (content.backColor) {
        this.setState({
          backColor : content.backColor
        })
      }});
  }

  componentWillReceiveProps(nextProps) {
    window.location.reload();
  }

  render() {
    const screenHeight = window.screen.availHeight;
    const even = n => !(n % 2);
    const lang = localStorage.getItem("i18nextLng");
    const options = {
      loop: false,
      margin: 10,
      rewind: true,
      navText: [
        `<i class="fas fa-chevron-left"></i>`,
        `<i class="fas fa-chevron-right"></i>`,
      ],
      nav: true,
      navClass: [prev, next],
      responsive: {
        0: {
          items: 1,
        },
        600: {
          items: 2,
        },
        1100: {
          items: 3,
        },
        1500: {
          items: 4,
        },
      },
    };

    let showModalHandler = (id) => {
      this.setState({
        showModal: true,
        selectedProduct: id,
      });
    };
    let closeModalHandler = () => {
      this.setState({
        showModal: false,
      });
    };
    let product;

    let productDetails = productData

      .filter(
        (productDetail) => productDetail.id === this.state.selectedProduct
      )
      .map((content, index) => {

        return (
          <ProductDetail
            showModal={this.state.showModal}
            closeModal={() => closeModalHandler()}
            id={content.id}
            key={content.id + index}
            title={lang === 'ru' ? content.titleRu : content.titleEn}
            types={content.types}
            color={content.color}
            context={content.contextEn}
            src={require(`../../../datas/Products/${content.img}`)}
          />
        );
      });

    let data = productData
      .filter((item) => item.category === this.props.match.params.productId)
      .map((content, index, dataArray) => {
        if (!even(index) && screenHeight >= 768) {
          return null;
        }
        let nextElem = dataArray[index + 1];

        if(nextElem !== undefined && screenHeight >= 768) {
          product = (
              <>
                <div className="item" key={content.id + index}>
                  <Product
                      clicked={() => showModalHandler(content.id)}
                      id={content.id}
                      backColor={content.backColor}
                      title={lang === 'ru' ? content.titleRu : content.titleEn}
                      src={require(`../../../datas/Products/${content.img}`)}
                  />
                  <Product
                      clicked={() => showModalHandler(nextElem.id)}
                      id={nextElem.id}
                      backColor={nextElem.backColor}
                      title={lang === 'ru' ? nextElem.titleRu : nextElem.titleEn}
                      src={require(`../../../datas/Products/${nextElem.img}`)}
                  />
                </div>
              </>
          );
        } else {
          product = (
              <>
                <div className="item" key={content.id + index}>
                  <Product
                      clicked={() => showModalHandler(content.id)}
                      id={content.id}
                      backColor={content.backColor}
                      title={lang === 'ru' ? content.titleRu : content.titleEn}
                      src={require(`../../../datas/Products/${content.img}`)}
                  />
                </div>
              </>
          );
        }

        return product;
      });

    return (
      <>
        <div
          style={{
            background: this.state.backColor,
            position: "relative",
            width: "100%",
            height: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <ReactOwlCarousel
            className={classes.OwlCustom}
            {...options}
          >
            {data}
          </ReactOwlCarousel>
        </div>
        {productDetails}
      </>
    );
  }
}

export default withRouter(Products);
