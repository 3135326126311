import React from "react";
import { Link } from "react-router-dom";
import classes from "./MobileInnerNavigation.module.css";
import posed from "react-pose";

const Sidebar = posed.nav({
  open: { height: "240px", staggerChildren: 150 },
  closed: { height: 0 },
});

const NavItem = posed.li({
  open: { opacity: 1, height: "40px" },
  closed: { opacity: 0, height: 0 },
});

function MobileInnerNavigation(props) {
  const lang = localStorage.getItem("i18nextLng");
  return (
    <Sidebar pose={props.isOpen ? "open" : "closed"}>
      <ul className={classes.InnerNavigation}>
        {props.navItems.map(({ url, titleEn, titleRu }, index) => {
          return (
            <NavItem key={index}>
              <Link onClick={props.clicked} to={url}>
                  {lang === 'en' ? titleEn : titleRu}
              </Link>
            </NavItem>
          );
        })}
      </ul>
    </Sidebar>
  );
}

export default MobileInnerNavigation;
