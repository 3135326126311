import React from "react";
import Toolbar from "./Toolbar/Toolbar";


function Layout(props) {

  return (
    <>
      <Toolbar />
      <main style={{height : "100vh"}}>{props.children}</main>
    </>
  );
}

export default Layout;
