import React from "react";
import {
  NavLink,
  withRouter,
} from "react-router-dom";
import classes from "./MobileNavigation.module.css";
import posed from "react-pose";
import { useState } from "react";
import Backdrop from "../../../../../UI/Backdrop/Backdrop";
import MobileInnerNavigation from "../MobileInnerNavigation/MobileInnerNavigation";
import { useTranslation } from 'react-i18next';
import LanguageSelect from "../../../../LanguageSelect/LanguageSelect";

const MobileNav = posed.ul({
  active: {
    transform: "translateX(0%)",
    opacity: 1,
    staggerChildren: 150
  },
  deactive: {
    transform: "translateX(100%)",
    opacity: 0
  }
});
const MobileLink = posed.li({
  active: {
    opacity: 1,
    transform: "translateX(0%)"
  },
  deactive: {
    opacity: 0,
    transform: "translateX(100%)"
  }
});

function MobileNavigation(props) {
  const [toggleDropdown, setToggleDropdown] = useState(false);

  let IconClasses = ["fas", "fa-caret-down"];
  if (toggleDropdown) {
    IconClasses = ["fas", "fa-caret-up"];
  }

  const closedMenuHandler = () => {
    props.deactive(false);
  };

  const { t } = useTranslation();

  return (
    <>
      <Backdrop show={props.active} clicked={closedMenuHandler} />
      <MobileNav
        pose={props.active ? "active" : "deactive"}
        className={classes.Nav}
      >
        <MobileLink onClick={closedMenuHandler}>
          <NavLink to="/">{t('navigation.home')}</NavLink>
        </MobileLink>

        <MobileLink onClick={() => setToggleDropdown(!toggleDropdown)}>
          <NavLink to="/" className={classes.Disabled}>
            {t('navigation.ourProducts')} <i className={IconClasses.join(" ")}></i>
          </NavLink>

          <MobileInnerNavigation
            clicked={closedMenuHandler}
            navItems={props.InnerNavLinks}
            isOpen={toggleDropdown}
          />
        </MobileLink>

        <MobileLink onClick={closedMenuHandler}>
          <NavLink to="/about">{t('navigation.about')}</NavLink>
        </MobileLink>

        <MobileLink onClick={closedMenuHandler}>
          <NavLink to="/contact">{t('navigation.contact')}</NavLink>
        </MobileLink>

        <MobileLink onClick={closedMenuHandler}>
          <NavLink to="/distributors">{t('navigation.distributors')}</NavLink>
        </MobileLink>

        <LanguageSelect />
      </MobileNav>
    </>
  );
}

export default withRouter(MobileNavigation);
