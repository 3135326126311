import React, { useState } from "react";
import DesktopNavigation from "./DesktopNavigation/DesktopNavigation";
import MobileNavigation from "./MobileNavigation/MobileNavigation";
import { withRouter } from "react-router-dom";
import MobileDetect from "../../../Helpers/MobileDetect";
import dataNavigation from "../../../../datas/Navigation/DataNavigation";

function Navigation(props) {
  const InnerNavLinks = dataNavigation;
  const [isHover, setHover] = useState(false);

  const mouseLeaveHandler = () => {
    setHover(false);
  };
  const mouseEnterHandler = () => {
    setHover(true);
  };

  const mobileDetect = MobileDetect();

  return (
    !mobileDetect
    ?
      <>
      <DesktopNavigation
          isHover={isHover}
          setHover={setHover}
          leaveHandler={mouseLeaveHandler}
          enterHandler={mouseEnterHandler}
          InnerNavLinks={InnerNavLinks} />
      </>
    :
      <>
      <MobileNavigation active={props.active} deactive={props.deactive} InnerNavLinks={InnerNavLinks}  />
      </>
  );
}

export default withRouter(Navigation);
