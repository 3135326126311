// import a from "./Images";

const Homepage = [
  {
    id: 1,
    backImg: "Images/pasta-bg.png",
    pageLink: "/our-products/pasta",
    backColor:
      "linear-gradient(90deg, rgba(191,26,15,1) 54%, rgba(36,0,0,1) 100%)",
    titleEn: "Pasta",
    titleRu: "Паста",
    contentEn: "Томатная паста марки «Кубань Продукт» производится из отборных и экологически чистых томатов по новейшим технологиям и на современном оборудовании. Благодаря этому наша паста – это натуральный продукт настоящего кубанского качества!\n" +
        "Готовая томатная паста марки «Кубань Продукт» фасуется в стеклянные банки объёмом: 280 гр, 500 гр, 530 гр и 720 гр, а также жестяные банки: 70 гр, 140 гр, 380гр, 770 гр и 3000гр.",
    contentRu:
        "Томатная паста марки «Кубань Продукт» производится из отборных и экологически чистых томатов по новейшим технологиям и на современном оборудовании. Благодаря этому наша паста – это натуральный продукт настоящего кубанского качества!\n" +
        "Готовая томатная паста марки «Кубань Продукт» фасуется в стеклянные банки объёмом: 280 гр, 500 гр, 530 гр и 720 гр, а также жестяные банки: 70 гр, 140 гр, 380гр, 770 гр и 3000гр.",
    img: "Images/pasta.png"
  },
  {
    id: 2,
    pageLink: "/our-products/pickle",
    backImg: "Images/pickle-bg.png",
    backColor:
      "linear-gradient(90deg, rgb(20, 117, 40) 100%, rgba(0, 0, 0, 0.84) 100%)",
    titleEn: "pickle",
    titleRu: "Соленья",
    contentEn:
      "Для производства овощей маринованных и соленных ТМ «Кубань Продукт» используются огурцы и томаты отборные, которые выращены на собственных полях с применением новейших технологий. Огромное внимание ТМ «Кубань Продукт» уделяет контролю качества выпускаемой продукции и осуществляет жесткий контроль всех этапов процесса производства.",
    contentRu:
        "Для производства овощей маринованных и соленных ТМ «Кубань Продукт» используются огурцы и томаты отборные, которые выращены на собственных полях с применением новейших технологий. Огромное внимание ТМ «Кубань Продукт» уделяет контролю качества выпускаемой продукции и осуществляет жесткий контроль всех этапов процесса производства.",
    img: "Images/pickle.png"
  },
  {
    id: 3,
    pageLink: "/our-products/jam",
    backImg: "Images/jam-bg.png",
    backColor:
      "linear-gradient(90deg, rgba(221,27,66,1) 29%, rgba(159,0,28,1) 100%)",
    titleEn: "jam",
    titleRu: "варенье",
    contentEn: "В нашем производстве варенья ТМ «Кубань Продукт» мы используем современные технологии, что позволяет нам выпускать продукцию со вкусом домашнего производства.\n" +
      "Мы производим следующие виды варенья под ТМ «Кубань Продукт»: варенье из грецкого ореха, варенье из белой черешни, варенье айвовое, варенье из инжира, варенье ежевичное, варенье из белой черешни, варенье из кизила , варенье малиновое, варенье клубничное, варенье абрикосовое и варенье вишнёвое.",
    contentRu: "В нашем производстве варенья ТМ «Кубань Продукт» мы используем современные технологии, что позволяет нам выпускать продукцию со вкусом домашнего производства.\n" +
        "Мы производим следующие виды варенья под ТМ «Кубань Продукт»: варенье из грецкого ореха, варенье из белой черешни, варенье айвовое, варенье из инжира, варенье ежевичное, варенье из белой черешни, варенье из кизила , варенье малиновое, варенье клубничное, варенье абрикосовое и варенье вишнёвое.",
    img: "Images/jam.png"
  },

  {
    id: 4,
    backImg: "Images/composto-bg.png",
    pageLink: "/our-products/composto",
    backColor:
    "linear-gradient(90deg, rgba(231,150,0,1) 20%, rgba(175,4,4,1) 100%)",
    titleEn: "composto",
    titleRu: "компоты",
    contentEn: "Производство компотов марки ТМ «Кубань Продукт» происходит на автоматизированной линии под постоянным контролем лаборатории — от входного сырья до упаковки.\n" +
        "\n" +
        "Для производства используются фрукты, которые выращены на территории Кубани .",
    contentRu:"Производство компотов марки ТМ «Кубань Продукт» происходит на автоматизированной линии под постоянным контролем лаборатории — от входного сырья до упаковки.\n" +
        "\n" +
        "Для производства используются фрукты, которые выращены на территории Кубани .",
    img: "Images/composto.png"
  },
  {
    id: 5,
    backImg: "Images/narsharab-bg.png",
    pageLink: "/our-products/narsharab",
    backColor:
      "linear-gradient(90deg, rgba(191,26,15,1) 54%, rgba(36,0,0,1) 100%)",
    titleEn: "narsharab",
    titleRu: "наршараб",
    contentEn:
      "Наршараб ТМ КУБАНЬ ПРОДУКТ — это приправа приготовленная получаемая методомv сгущения гранатового сока. Его обычно используют в качестве приправы к мясным и рыбным блюдам, а также к овощам и различным гарнирам.",
    contentRu:
        "Наршараб ТМ КУБАНЬ ПРОДУКТ — это приправа приготовленная получаемая методомv сгущения гранатового сока. Его обычно используют в качестве приправы к мясным и рыбным блюдам, а также к овощам и различным гарнирам.",
    img: "Images/narsharab.png"
  },
  {
    id: 5,
    backImg: "Images/pasta-bg.png",
    pageLink: "/our-products/tomatos",
    backColor:
      "linear-gradient(90deg, rgba(191,26,15,1) 54%, rgba(36,0,0,1) 100%)",
    titleEn: "tomatos",
    titleRu: "Томаты",
    contentEn:
      "Томаты очищенные в собственном соку ТМ КУБАНЬ ПРОДУКТ произведены из томатов выращенных на собственных полях в Краснодарском крае.\n" +
        "Уникальная рецептура и современные технологии помогают сохранить большинство питательных веществ и полезных свойств. Томаты очищенные в собственном соку ТМ КУБАНЬ ПРОДУКТ обладают отменным вкусом и являются незаменимым блюдом для тех, кто соблюдает пост или придерживается диеты. Используется не только как готовое блюдо, но и станет дополнительным ингредиентом многих блюд.",
    contentRu:
        "Томаты очищенные в собственном соку ТМ КУБАНЬ ПРОДУКТ произведены из томатов выращенных на собственных полях в Краснодарском крае.\n" +
        "Уникальная рецептура и современные технологии помогают сохранить большинство питательных веществ и полезных свойств. Томаты очищенные в собственном соку ТМ КУБАНЬ ПРОДУКТ обладают отменным вкусом и являются незаменимым блюдом для тех, кто соблюдает пост или придерживается диеты. Используется не только как готовое блюдо, но и станет дополнительным ингредиентом многих блюд.",
    img: "Images/tomatos.png"
  },
  {
    id: 6,
    backImg: "Images/appetizer-bg.png",
    pageLink: "/our-products/appetizer",
    backColor:
      "linear-gradient(90deg, rgb(20, 117, 40) 100%, rgba(0, 0, 0, 0.84) 100%)",
    titleEn: "appetizer",
    titleRu: "закуски",
    contentEn:
      "Закуски ТМ Кубань Продукт приготовлена из свежих , спелых и отборных овощей с добавлением специй . В процессе производства применяются уникальные технологии благодаря которым , вкус и запах напоминает с детства.\n" +
        "Закуски ТМ Кубань Продукт можно подавать как индивидуальные блюда , как гарниры к мясу, рыбе и птице, а так же можно использовать для приготовления бутербродов.",
    contentRu:
        "Закуски ТМ Кубань Продукт приготовлена из свежих , спелых и отборных овощей с добавлением специй . В процессе производства применяются уникальные технологии благодаря которым , вкус и запах напоминает с детства.\n" +
        "Закуски ТМ Кубань Продукт можно подавать как индивидуальные блюда , как гарниры к мясу, рыбе и птице, а так же можно использовать для приготовления бутербродов.",
    img: "Images/appetizer.png"
  }
];

export default Homepage;
