const AboutData = {
    aboutEn: "ООО КУБАНЬ ПРОДУКТ - современная российская компания, основанная в 2012г.</br>" +
        "Фундаментом для строительства предприятий компании «Кубань Продукт» в России стал Международный экономический форум «Сочи-2009», где компания «Анадолу Инвестмент» и кубанские власти подписали соответствующее соглашение.</br>" +
        "Параллельно со строительством консервного завода создавалось сельскохозяйственное предприятие «Кубань Продукт» для собственной сырьевой базы площадью более 1,5 тыс. га. с применением передовых технологий для выращивания экологически чистого сырья .</br>" +
        "На универсальных ,автоматизированных линиях мы производим томатную пасту, томатные соусы, маринованные и соленные овощи и овощные ассорти, закуски овощные, томаты очищенные и томаты очищенные резанные в томатном соке , огурцы резанные маринованные и соленные , консервированная фасоль , компоты и варенье под торговой маркой КУБАНЬ ПРОДУКТ.</br>" +
        "Уникальная рецептура позволяет максимально передать вкус натуральных кубанских овощей. Мы гордимся тем, что наша компания первая в России, которая освоила производство очищенных томатов в собственном соку .</br>" +
        "Главный критерий в развитии предприятия – контроль над выпускаемой продукцией, основанный на внедрении систем менеджмента качества и безопасности пищевой продукции, включающие принципы НАССР, сертификации ЕАС. Высочайший уровень</br>" +
        "Профессионализма позволяет выпускать широкий ассортимент качественной продукции.</br>" +
        "Мы признательны нашим партнерам и клиентам за выбор продукции «Кубань Продукт», которая символизирует качество, плодородие и процветание.",
    aboutRu: "ООО КУБАНЬ ПРОДУКТ - современная российская компания , основанная в 2012г.</br>" +
        "Фундаментом для строительства предприятий компании «Кубань Продукт» в России стал Международный экономический форум «Сочи-2009», где компания «Анадолу Инвестмент» и кубанские власти подписали соответствующее соглашение.</br>" +
        "Параллельно со строительством консервного завода создавалось сельскохозяйственное предприятие «Кубань Продукт» для собственной сырьевой базы площадью более 1,5 тыс. га. с применением передовых технологий для выращивания экологически чистого сырья .</br>" +
        "На универсальных ,автоматизированных линиях мы производим томатную пасту, томатные соусы, маринованные и соленные овощи и овощные ассорти, закуски овощные, томаты очищенные и томаты очищенные резанные в томатном соке , огурцы резанные маринованные и соленные , консервированная фасоль , компоты и варенье под торговой маркой КУБАНЬ ПРОДУКТ.</br>" +
        "Уникальная рецептура позволяет максимально передать вкус натуральных кубанских овощей. Мы гордимся тем, что наша компания первая в России, которая освоила производство очищенных томатов в собственном соку.</br>" +
        "Главный критерий в развитии предприятия – контроль над выпускаемой продукцией, основанный на внедрении систем менеджмента качества и безопасности пищевой продукции, включающие принципы НАССР, сертификации ЕАС. Высочайший уровень</br>" +
        "Профессионализма позволяет выпускать широкий ассортимент качественной продукции.</br>" +
        "Мы признательны нашим партнерам и клиентам за выбор продукции «Кубань Продукт», которая символизирует качество, плодородие и процветание.",

};

export default AboutData;
