import React from "react";
import classes from "./MenuIcon.module.css";
import posed from "react-pose";

const FirstDiv = posed.div({
  active: {
    transform: "rotate(45deg)",
    top: "9px"
  },
  deactive: {
    transform: "rotate(0deg)",
    top: 0
  }
});

const MiddleDiv = posed.div({
  active: {
    opacity: 0,
    left: "200%"
  },
  deactive: {
    opacity: 1,
    left: 0
  }
});

const LastDiv = posed.div({
  active: {
    transform: "rotate(-45deg)",
    top: "-7px"
  },
  deactive: {
    transform: "rotate(0deg)",
    top: 0
  }
});

function MenuIcon(props) {
  const toggleActive = () => {
    props.deactive(!props.active);
  };

  return (
    <div className={classes.MenuIconWrapper} onClick={toggleActive}>
      <div className={classes.MenuIcon}>
        <FirstDiv pose={props.active ? "active" : "deactive"}></FirstDiv>
        <MiddleDiv pose={props.active ? "active" : "deactive"}></MiddleDiv>
        <LastDiv pose={props.active ? "active" : "deactive"}></LastDiv>
      </div>
    </div>
  );
}

export default MenuIcon;
