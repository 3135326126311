import React from "react";

import ArrowDropDown from "@material-ui/icons/ArrowDropDown";
import Button from "@material-ui/core/Button/index";
import Popover from "@material-ui/core/Popover/index";
import List from "@material-ui/core/List/index";
import ListItem from "@material-ui/core/ListItem/index";
import style from "./DesktopLanguageSelect.module.css"

import i18n from 'i18next';

const languageMap = {
    en: { label: "Eng", dir: "ltr", active: true },
    ru: { label: "Рус", dir: "ltr", active: false }
};

const DesktopLanguageSelect = () => {
    const selected = localStorage.getItem("i18nextLng") || "ru";
    const [menuAnchor, setMenuAnchor] = React.useState(null);

    React.useEffect(() => {
        document.body.dir = languageMap[selected].dir;
    }, [menuAnchor, selected]);

    return (
        <div className={style.languageSelector}>
            <Button onClick={({ currentTarget }) => setMenuAnchor(currentTarget)}>
                {languageMap[selected].label}
                <ArrowDropDown fontSize="small" />
            </Button>
            <Popover
                open={!!menuAnchor}
                anchorEl={menuAnchor}
                onClose={() => setMenuAnchor(null)}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "right"
                }}
                transformOrigin={{
                    vertical: "top",
                    horizontal: "right"
                }}
            >
                <div>
                    <List>
                        {Object.keys(languageMap)?.map(item => (
                            <ListItem
                                button
                                key={item}
                                onClick={() => {
                                    i18n.changeLanguage(item);
                                    setMenuAnchor(null);
                                }}
                            >
                                {languageMap[item].label}
                            </ListItem>
                        ))}
                    </List>
                </div>
            </Popover>
        </div>
    );
};


export default DesktopLanguageSelect;
