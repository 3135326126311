import React from "react";
import classes from "./Contact.module.css"

import { useTranslation } from 'react-i18next';

function Contact(props) {
  const { t } = useTranslation();

  return (
    <>
      <div className={classes.Contact}>
        <div
          className={classes.BackImg}
        >
        </div>
        <div className={classes.ContactWrapper}>
          <div className={classes.ContactDetails}>
            <div className={classes.ContentDetails}>

              <div className={classes.Content}>
                <span className={classes.key}>{t("contact.phone")}:</span>
                <span className={classes.value}>(861 55) 3 26 42</span>
              </div>

              <div className={classes.Content}>
                <span className={classes.key}>{t("contact.email")}:</span>
                <span className={classes.value}>info@kubanproduct.org</span>
              </div>

              <address className={classes.Content}>
                <span className={classes.key}>{t("contact.address")}:</span>
                <span className={classes.value}>
                  352631, Россия, Краснодарский край, г.Белореченск, ул.Бригадная, 1Б
                </span>
              </address>

              {/*<div className={classes.Content}>*/}
              {/*  <span className={classes.key}>{t("contact.hotLine")}:</span>*/}
              {/*  <span className={classes.value}>8 800 100 55 28 ({t("contact.freeCalls")})</span>*/}
              {/*</div>*/}
            </div>
          </div>
          <div className={classes.Map}>
            {/*<h3>Где купить</h3>*/}
            {/*<ReactOwlCarousel*/}
            {/*    className={classes.OwlCustom}*/}
            {/*    {...options}*/}
            {/*>*/}
            {/*  {data}*/}
            {/*</ReactOwlCarousel>*/}
          </div>
        </div>
      </div>
    </>
  );
}

export default Contact;
