const ContactData = [
    {
        region: 'Москва, Московская область , Нижний Новгород и Центральная Россия',
        title: 'ООО "Типорт"',
        phones: [
            '89267046506',
            '89166566464',
            '89169840087',
        ]
    },
    {
        region: 'Санкт – Петербург, Ленинградская область и Архангельская область',
        title: 'ООО "Чинар"',
        phones: [
            '89062724098',
            '89819866288',
            '89116788888',
        ]
    },
    {
        region: 'Воронеж, Воронежская область, Белгородская область, Курская область, Липецкая область, Орловская область и Тамбовская область',
        title: 'ООО "Смарт Трейд" и ООО "Типорт"',
        phones: [
            '89261584757',
        ]
    },
    {
        region: 'Саратов, Самара, Пензенская область и республика Мордовия',
        title: 'ООО "Волга Трейд"',
        phones: [
            '89050340641',
        ]
    },
    {
        region: 'Республики Татарстан и Башкортостан, Оренбургская область и Ульяновская область',
        title: 'ООО "Татарсун"',
        phones: [
            '89375232299',
            '89872710750',
            '89228276736',
        ]
    },
    {
        region: 'Екатеринбург, Свердловская область, Челябинская область, Пермская область и Тюменская область',
        title: 'ООО "Урал трейд"',
        phones: [
            '89321296515',
            '89630373201',
            '89226051197',
            '89961882221',
        ]
    },
    {
        region: 'Новосибирск, Хабаровск, Якутия и Красноярск',
        title: 'ООО "Сибирь трейд"',
        phones: [
            '89139514455',
            '89137154433',
            '89239443535',
            '89830002277',
            '89231871060',
        ]
    },
    {
        region: 'Ставропольский край, республика Ингушетия, республика Северная Осетия, Кабардино-Балкарская Республика и Карачаево-Черкесская Республика',
        title: 'ООО "Максима"',
        phones: [
            '89289157451',
            '89637775035',
            '89887375616',
        ]
    },
    {
        region: 'Республика Дагестан, Чеченская республика и Астраханская область',
        title: 'ООО "Чайный Дом"',
        phones: [
            '89280485333',
            '89884444845',
            '89384509595',
        ]
    },
    {
        region: 'Краснодарский край, Ростовская область, Волгоградская область и республика Калмыкия',
        title: 'ООО "Ти Трейд"',
        phones: [
            '89882451155',
            '89286626143',
            '89044492450',
            '89616890607',
        ]
    },
    {
        region: 'Республика Крым',
        title: 'ООО "Винный Альянс"',
        phones: [
            '89787091530',
            '89787691500',
        ]
    },
    {
        region: 'Калининградская область',
        title: 'ООО "Карамелька"',
        phones: [
            '89114530886',
            '89062386222',
        ]
    },
];

export default ContactData;
