const AchievementsData = [
    {
        id: 0,
        image: "",
        video: "LasgHGvzFLU",
        textEn: "ООО КУБАНЬ ПРОДУКТ - современная российская компания, основанная в 2012г.</br>" +
            "Фундаментом для строительства предприятий компании «Кубань Продукт» в России стал Международный экономический форум «Сочи-2009», где компания «Анадолу Инвестмент» и кубанские власти подписали соответствующее соглашение.</br>",
        textRu: "ООО КУБАНЬ ПРОДУКТ - современная российская компания , основанная в 2012г.</br>" +
            "Фундаментом для строительства предприятий компании «Кубань Продукт» в России стал Международный экономический форум «Сочи-2009», где компания «Анадолу Инвестмент» и кубанские власти подписали соответствующее соглашение.</br>"
    },
    {
        id: 1,
        image: "",
        video: "",
        textEn: "Параллельно со строительством консервного завода создавалось сельскохозяйственное предприятие «Кубань Продукт» для собственной сырьевой базы площадью более 1,5 тыс. га. с применением передовых технологий для выращивания экологически чистого сырья .</br>" +
            "На универсальных ,автоматизированных линиях мы производим томатную пасту, томатные соусы, маринованные и соленные овощи и овощные ассорти, закуски овощные, томаты очищенные и томаты очищенные резанные в томатном соке , огурцы резанные маринованные и соленные , консервированная фасоль , компоты и варенье под торговой маркой КУБАНЬ ПРОДУКТ.</br>" +
            "Уникальная рецептура позволяет максимально передать вкус натуральных кубанских овощей. Мы гордимся тем, что наша компания первая в России, которая освоила производство очищенных томатов в собственном соку .</br>" +
            "Главный критерий в развитии предприятия – контроль над выпускаемой продукцией, основанный на внедрении систем менеджмента качества и безопасности пищевой продукции, включающие принципы НАССР, сертификации ЕАС. Высочайший уровень</br>" +
            "Профессионализма позволяет выпускать широкий ассортимент качественной продукции.</br>" +
            "Мы признательны нашим партнерам и клиентам за выбор продукции «Кубань Продукт», которая символизирует качество, плодородие и процветание.",
        textRu: "Параллельно со строительством консервного завода создавалось сельскохозяйственное предприятие «Кубань Продукт» для собственной сырьевой базы площадью более 1,5 тыс. га. с применением передовых технологий для выращивания экологически чистого сырья .</br>" +
            "На универсальных ,автоматизированных линиях мы производим томатную пасту, томатные соусы, маринованные и соленные овощи и овощные ассорти, закуски овощные, томаты очищенные и томаты очищенные резанные в томатном соке , огурцы резанные маринованные и соленные , консервированная фасоль , компоты и варенье под торговой маркой КУБАНЬ ПРОДУКТ.</br>" +
            "Уникальная рецептура позволяет максимально передать вкус натуральных кубанских овощей. Мы гордимся тем, что наша компания первая в России, которая освоила производство очищенных томатов в собственном соку.</br>" +
            "Главный критерий в развитии предприятия – контроль над выпускаемой продукцией, основанный на внедрении систем менеджмента качества и безопасности пищевой продукции, включающие принципы НАССР, сертификации ЕАС. Высочайший уровень</br>" +
            "Профессионализма позволяет выпускать широкий ассортимент качественной продукции.</br>" +
            "Мы признательны нашим партнерам и клиентам за выбор продукции «Кубань Продукт», которая символизирует качество, плодородие и процветание.",
    },
    {
        id: 2,
        textEn: "С 2012 года наша компания была первой из предприятий, кто запустил производство томатной пасты в форматах 70гр и 140 гр в железных банках на территории РФ.",
        textRu: "С 2012 года наша компания была первой из предприятий, кто запустил производство томатной пасты в форматах 70гр и 140 гр в железных банках на территории РФ.",
        image: "Images/pasta.png"
    },
    {
        id: 3,
        textEn: "С 2013 года запустили производства компотов",
        textRu: "С 2013 года запустили производства компотов",
        image: "Images/komposto.png"
    },
    {
        id: 4,
        textEn: "С 2014 года наша компания была первой, кто смог произвести на территории РФ томаты очищенные в томатном соке",
        textRu: "С 2014 года наша компания была первой, кто смог произвести на территории РФ томаты очищенные в томатном соке",
        image: "Images/peeled_tomatoes_juice.png"
    },
    {
        id: 5,
        textEn: "С 2015 наша компания была первой, кто смог произвести соленые огурцы в стеклянной банке",
        textRu: "С 2015 наша компания была первой, кто смог произвести соленые огурцы в стеклянной банке",
        image: "Images/pickle.png"
    },
    {
        id: 6,
        textEn: "С 2017 наша компания первое и на данный момент единственное предприятие на территории РФ, которое производит томаты очищенные резанные в томатном соке",
        textRu: "С 2017 наша компания первое и на данный момент единственное предприятие на территории РФ, которое производит томаты очищенные резанные в томатном соке",
        image: "Images/sliced_tomatoes.png"
    },
    {
        id: 7,
        textEn: "Во второй половине 2017г. произошел рестайлинг ТМ Кубань Продукт. В первую очередь мы учли пожелания и замечания наших Покупателей. Во-вторых, мы подчеркнули новую философию бренда: Кубанский продукт, натуральный и с сельским колоритом. В третьих мы преследовали цель в выделении продукцию на полке, сделав ее яркой, солнечной и вкусной.",
        textRu: "Во второй половине 2017г. произошел рестайлинг ТМ Кубань Продукт. В первую очередь мы учли пожелания и замечания наших Покупателей. Во-вторых, мы подчеркнули новую философию бренда: Кубанский продукт, натуральный и с сельским колоритом. В третьих мы преследовали цель в выделении продукцию на полке, сделав ее яркой, солнечной и вкусной.",
        image: "Images/restyling.png"
    },
    {
        id: 8,
        textEn: "В 2018г на международной выставке «ПРОДЭКСПО» в Москве получили в конкурсах «ПРОДУКТ ГОДА 2018» почетный Кубок за импортозамещении и Золотую звезду за лучший инновационный продукт.",
        textRu: "В 2018г на международной выставке «ПРОДЭКСПО» в Москве получили в конкурсах «ПРОДУКТ ГОДА 2018» почетный Кубок за импортозамещении и Золотую звезду за лучший инновационный продукт.",
        image: "Images/exhibition.png"
    },
    {
        id: 9,
        textEn: "В 2018г запустили производство Лечо 680 гр в стекло банке",
        textRu: "В 2018г запустили производство Лечо 680 гр в стекло банке",
        image: "Images/lecho.png"
    },
    {
        id: 10,
        textEn: "В 2018 году было запущено для расширение линейки томатной пасты 1000гр в стекло банке",
        textRu: "В 2018 году было запущено для расширение линейки томатной пасты 1000гр в стекло банке",
        image: "Images/pasta_1000.png"
    },
    {
        id: 11,
        textEn: "С 2018г продукция ТМ КУБАНЬ ПРОДУКТ постоянно побеждает в краевом конкурсе «СДЕЛАНО НА КУБАНИ» благодаря этому на этикетках продукции стоит знак качество «СДЕЛАНО НА КУБАНИ»",
        textRu: "С 2018г продукция ТМ КУБАНЬ ПРОДУКТ постоянно побеждает в краевом конкурсе «СДЕЛАНО НА КУБАНИ» благодаря этому на этикетках продукции стоит знак качество «СДЕЛАНО НА КУБАНИ»",
        image: "Images/made_in_kuban.png"
    },
    {
        id: 12,
        textEn: "В 2019 году запущены новые продукции КУБАНЬ ПРОДУКТ Икра из кабачков 530гр в стеклянной банке и КУБАНЬ ПРОДУКТ корнишоны маринованные 390гр в стеклянной банке",
        textRu: "В 2019 году запущены новые продукции КУБАНЬ ПРОДУКТ Икра из кабачков 530гр в стеклянной банке и КУБАНЬ ПРОДУКТ корнишоны маринованные 390гр в стеклянной банке",
        image: "Images/squash_caviar_peekle.png"
    },
    {
        id: 13,
        textEn: "В 2020 запустили новую продукцию ТМ КУБАНЬ ПРОДУКТ Маринованные огурцы резанные кубиками 400гр в ж/б и ТМ КУБАНЬ ПРОДУКТ Соленные огурцы резанные кубиками 400гр в ж/б",
        textRu: "В 2020 запустили новую продукцию ТМ КУБАНЬ ПРОДУКТ Маринованные огурцы резанные кубиками 400гр в ж/б и ТМ КУБАНЬ ПРОДУКТ Соленные огурцы резанные кубиками 400гр в ж/б",
        image: "Images/cucumbers.png"
    },
];

export default AchievementsData;
