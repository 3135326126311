import React from "react";

import ToggleButtonGroup from "@material-ui/lab/ToggleButtonGroup";
import ToggleButton from "@material-ui/lab/ToggleButton";
import style from "./MobileLanguageSelect.module.css"

import i18n from 'i18next';

const languageMap = {
    en: { label: "Eng", dir: "ltr", active: true },
    ru: { label: "Рус", dir: "ltr", active: false }
};

const MobileLanguageSelect = () => {
    const selected = localStorage.getItem("i18nextLng") || "ru";

    const [menuAnchor, setMenuAnchor] = React.useState(null);
    React.useEffect(() => {
        document.body.dir = languageMap[selected].dir;
    }, [menuAnchor, selected]);

    return (
        <div className={style.mobileLanguageSelector}>
            <ToggleButtonGroup>
            {Object.keys(languageMap)?.map(item => (
                <ToggleButton
                    key={item}
                    selected={selected === item}
                    component="button"
                    value={item}
                    onClick={() => {
                        i18n.changeLanguage(item);
                        setMenuAnchor(null);
                    }}
                >
                    {languageMap[item].label}
                </ToggleButton>
            ))}
            </ToggleButtonGroup>
        </div>

    );
};

export default MobileLanguageSelect;
