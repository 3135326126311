import React from "react";
import Backdrop from "../Backdrop/Backdrop";
import posed from "react-pose";
import classes from "./Modal.module.css";

const ModalDiv = posed.div({
  closed: {
    opacity: 0,
    transform: "translateY(-200%)",
    transition: {
      ease: "linear",
      duration: 400
    }
  },
  open: {
    opacity: 1,
    transform: "translateY(0%)",
    transition: {
      ease: "linear",
      duration: 400
    }
  }
});

function Modal(props) {
  
  return (
    <>
      <Backdrop clicked={props.close} show={props.show} />
      <ModalDiv className={classes.Modal} initialPose={"closed"} pose={props.show ? "open" : "closed"}>
        {props.children}
      </ModalDiv>
    </>
  );
}
export default Modal;
